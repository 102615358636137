export default {
  name: "SignaturePad",
  data() {
    return {};
  },
  methods: {
    async save() {
      const data = this.$refs.signaturePad.saveSignature();
      const image = await this.convertBase64ToBlob(data.data);
      const file = new File([image], "signature_image", {
        lastModified: new Date(),
        type: "image/png",
      });
      this.$emit("onSave", file);
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
  },
};
