import Mixins from "@/views/transactions/m-cif/mixins";

export default {
  name: "CifPersonApuppt",
  mixins: [Mixins],
  data() {
    return {
      form: {
        customerIdentity: "",
        businessLocation: "",
        customerProfile: "",
        transactionAmount: "",
        ownershipStructure: "",
        businessActivities: "",
        other: "",
        decision: "",
        riskLevelCriteria: "",
      },
      property: {
        modal: {
          showInfo: false,
        },
      },
      options: {
        decision: [],
      },
      valPojok: process.env.VUE_APP_IS_POJOK,
    };
  },
  methods: {
    backToList() {
      this.$router.push("/cif");
    },
    async submit() {
      console.log("async submit")
      this.$emit("submit", this.form);
    },
    openModalInfo() {
      this.property.modal.showInfo = true;
    },
    closeModalInfo() {
      this.property.modal.showInfo = false;
    },
    async getreferenceDecision() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "profile-status-risk/list",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.decision = [{ text: "--- Pilih ---", value: "" }];
          resp.data.data.map((i) => {
            const text = i.statusName;
            const value = i.statusId;
            this.options.decision.push({ text, value });
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    buildDto() {
      this.form = this.cifPersonDtoBuider();
    },
  },
  mounted() {
    this.getreferenceDecision();
    this.buildDto();
  },
};
