import Mixins from "@/views/transactions/m-cif/mixins";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "CifPersonAdditionalAddress",
  components: {
    PulseLoader,
  },
  mixins: [Mixins],
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      property: {
        listElement: {
          downloading: false,
          message: "",
        },
        modal: {
          formCifAdditionalAddress: false,
        },
        animation: {
          submitLoading: false,
        },
      },
      form: {
        cifId: "",
        cifAdditionalAddressId: "",
        cifAddressTypeId: "",
        cifAddress: "",
        cifVillageId: "",
        cifPostalCode: "",
        phoneNumber1: "",
        phoneNumber2: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      area: {
        provinceId: "",
        cityId: "",
        districtId: "",
      },
      table: {
        data: {
          cifAdditionalAddress: [],
        },
      },
      options: {
        province: [],
        city: [],
        district: [],
        village: [],
        addressType: [],
      },
    };
  },
  methods: {
    backToList() {
      this.$router.push("/cif");
    },
    async onChangeProvince() {
      this.options.city = [];
      this.options.district = [];
      this.options.village = [];
      this.form.cifPostalCode = "";
      const resp = await this.$store.dispatch(
        "GET_REFERENCE_FROM_MASTER_PARAMETER",
        {
          url: "area/city",
          params: {
            provinceId: this.area.provinceId,
            cityName: "",
            page: 0,
          },
        }
      );
      if (resp) {
        this.options.city = [{ text: "-- Pilih --", value: "" }];
        resp.data.data.content.map((i, index) => {
          const text = `${i.cityName}`.toUpperCase();
          const value = i.cityId;
          this.options.city.push({ text, value });
          if (index + 1 === resp.data.data.content.length) {
            if (this.area.cityId) {
              this.onChangeCity();
            }
          }
        });
      }
    },
    async onChangeCity() {
      this.options.district = [];
      this.options.village = [];
      this.form.cifPostalCode = "";
      const resp = await this.$store.dispatch(
        "GET_REFERENCE_FROM_MASTER_PARAMETER",
        {
          url: "area/district",
          params: {
            cityId: this.area.cityId,
            districtName: "",
            page: 0,
          },
        }
      );
      if (resp) {
        this.options.district = [{ text: "-- Pilih --", value: "" }];
        resp.data.data.content.map((i, index) => {
          const text = `${i.districtName}`.toUpperCase();
          const value = i.districtId;
          this.options.district.push({ text, value });
          if (index + 1 === resp.data.data.content.length) {
            if (this.area.cityId) {
              this.onChangeDistrct();
            }
          }
        });
      }
    },
    async onChangeDistrct() {
      this.options.village = [];
      this.form.cifPostalCode = "";
      const resp = await this.$store.dispatch(
        "GET_REFERENCE_FROM_MASTER_PARAMETER",
        {
          url: "area/village",
          params: {
            districtId: this.area.districtId,
            villageName: "",
            page: 0,
          },
        }
      );
      if (resp) {
        this.options.village = [{ text: "-- Pilih --", value: "" }];
        resp.data.data.content.map((i, index) => {
          const text = `${i.villageName}`.toUpperCase();
          const value = i.villageId;
          const postalcode = i.postalcode;
          this.options.village.push({ text, value, postalcode });
          if (index + 1 === resp.data.data.content.length) {
            if (this.area.districtId) {
              this.form.cifPostalCode = this.postalCodeFinder(
                this.options.village,
                this.form.cifVillageId
              );
            }
          }
        });
      }
    },
    getCifById() {
      this.form = {
        ...this.form,
        cifId: this.cifPersonDtoBuider().cifId,
      };
    },
    async submitCifAdditionalAddress() {
      if (this.validationDomain === 'pojok.bprshikp.co.id') {
      if (!window.navigator.onLine) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: this.form.cifAdditionalAddressId ? "PUT_DATA" : "POST_DATA",
          endPoint: "transaction",
          reqUrl: this.form.cifAdditionalAddressId
            ? `cif-additional-address/${this.form.cifAdditionalAddressId}`
            : "cif-additional-address",
          payload: this.form,
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open({
              message: "Sukses !",
              type: "is-info",
              duration: 1000,
              position: "is-top",
              queue: false,
            });
            this.closeModalCifAdditionalAddress();
            this.getCifAdditionalAddressByCifId();
          }
        }, 1500);
      } catch (error) {
        setTimeout(() => {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 1500);
      } finally {
        setTimeout(() => {
          this.property.animation.submitLoading = false;
        }, 1000);
      }
    } else {
      if (!window.navigator.onLine) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: this.form.cifAdditionalAddressId ? "PUT_DATA" : "POST_DATA",
          endPoint: "transaction",
          reqUrl: this.form.cifAdditionalAddressId
            ? `cif-additional-address/${this.form.cifAdditionalAddressId}`
            : "cif-additional-address",
          payload: this.form,
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
            this.closeModalCifAdditionalAddress();
            this.getCifAdditionalAddressByCifId();
          }
        }, 1500);
      } catch (error) {
        setTimeout(() => {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 1500);
      } finally {
        setTimeout(() => {
          this.property.animation.submitLoading = false;
        }, 1000);
      }
    }
    },
    async deleteCifAdditionalAddress(payload) {
      if (this.validationDomain==='pojok.bprshikp.co.id') {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Hapus Data Ini ?",
        type: "is-info",
        onConfirm: async () => {
          this.property.animation.submitLoading = true;
          try {
            const resp = await this.$store.dispatch({
              type: "DELETE_DATA",
              endPoint: "transaction",
              reqUrl:
                "cif-additional-address/" + payload.cifAdditionalAddressId,
            });
            setTimeout(() => {
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open({
                  message: "Sukses !",
                  type: "is-info",
                  duration: 1000,
                  position: "is-top",
                  queue: false,
                });
                setTimeout(() => {
                  this.getCifAdditionalAddressByCifId();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            }, 1500);
          } catch (error) {
            setTimeout(() => {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }, 1500);
          } finally {
            setTimeout(() => {
              this.property.animation.submitLoading = false;
            }, 1000);
          }
        },
      });
    } else {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Hapus Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          this.property.animation.submitLoading = true;
          try {
            const resp = await this.$store.dispatch({
              type: "DELETE_DATA",
              endPoint: "transaction",
              reqUrl:
                "cif-additional-address/" + payload.cifAdditionalAddressId,
            });
            setTimeout(() => {
              if (resp.data.code === "SUCCESS") {
                this.$buefy.snackbar.open(
                  this.$NotificationUtils.snackbarSuccess
                );
                setTimeout(() => {
                  this.getCifAdditionalAddressByCifId();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            }, 1500);
          } catch (error) {
            setTimeout(() => {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }, 1500);
          } finally {
            setTimeout(() => {
              this.property.animation.submitLoading = false;
            }, 1000);
          }
        },
      });
    }
    },
    closeModalCifAdditionalAddress() {
      this.form = {
        ...this.form,
        cifAdditionalAddressId: "",
        cifAddressTypeId: "",
        cifAddress: "",
        cifVillageId: "",
        cifPostalCode: "",
        phoneNumber1: "",
        phoneNumber2: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      };
      this.area = {
        provinceId: "",
        cityId: "",
        districtId: "",
      };
      this.options = {
        ...this.options,
        city: [],
        district: [],
        village: [],
      };
      this.property.modal.formCifAdditionalAddress = false;
    },
    openModalCifAdditionalAddress(payload) {
      this.form = {
        ...this.form,
        cifAdditionalAddressId: payload?.cifAdditionalAddressId,
        cifAddressTypeId: payload?.rcifAddressType?.cifAddressTypeId,
        cifAddress: payload?.cifAddress,
        cifVillageId: payload?.mvillage?.villageId,
        cifPostalCode: payload?.cifPostalCode,
        phoneNumber1: payload?.phoneNumber1,
        phoneNumber2: payload?.phoneNumber2,
        createdByUserCode: payload?.createdByUserCode,
        createdByUserName: payload?.createdByUserName,
        createdDate: payload?.createdDate,
        updatedDate: payload?.updatedDate,
        updatedByUserCode: payload?.updatedByUserCode,
        updatedByUserName: payload?.updatedByUserName,
        authorizedDate: payload?.authorizedDate,
        authorizedByUserCode: payload?.authorizedByUserCode,
        authorizedByUserName: payload?.authorizedByUserName,
      };
      this.area = {
        provinceId: payload?.mvillage?.mdistrict?.mcity?.mprovince?.provinceId,
        cityId: payload?.mvillage?.mdistrict?.mcity?.cityId,
        districtId: payload?.mvillage?.mdistrict?.districtId,
      };
      if (payload?.mvillage?.mdistrict?.mcity?.mprovince?.provinceId) {
        this.onChangeProvince();
      }
      this.property.modal.formCifAdditionalAddress = true;
    },
    async getReferenceAddressType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-address-type",
        });
        if (resp) {
          this.options.addressType = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((index) => {
            const text = index.cifAddressTypeName;
            const value = index.cifAddressTypeId;
            this.options.addressType.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceProvince() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "area/province",
            params: {
              provinceName: "",
              page: 0,
            },
          }
        );
        if (resp) {
          this.options.province = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.content.map((i) => {
            const text = `${i.provinceName}`.toUpperCase();
            const value = i.provinceId;
            this.options.province.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCifAdditionalAddressByCifId() {
      this.table.data.cifAdditionalAddress = [];
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          this.property.listElement.downloading = true;
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "cif-additional-address/cif-id/" + this.form.cifId,
          });
          console.log(resp);
          if (resp.data.code === "SUCCESS") {
            this.table.data.cifAdditionalAddress = resp.data.data;
          } else {
            this.property.listElement.message = resp.data.message;
          }
        } catch (error) {
          console.log(error.response);
          this.property.listElement.message = error.response
            ? `Gagal Mengunduh Data : ${error.response.statusText}`
            : "Terjadi Kesalahan";
        } finally {
          this.property.listElement.downloading = false;
        }
      }
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    this.getCifById();
    this.getReferenceAddressType();
    this.getReferenceProvince();
    this.getCifAdditionalAddressByCifId();
  },
};
