import Mixins from "@/views/transactions/m-cif/mixins";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "CifEnterpriseCompanyManagement",
  mixins: [Mixins],
  components: {
    PulseLoader,
  },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      property: {
        listElement: {
          downloading: false,
          message: "",
        },
        modal: {
          formCifCompanyManagement: false,
        },
        animation: {
          submitLoading: false,
        },
      },
      form: {
        cifId: "",
        managementSequence: 0,
        managementName: "",
        managementJobtitle: "",
        managementIdcardNumber: "",
        managementNpwpNumber: "",
        managementGenderId: "",
        managementStock: 0,
        managementPhoneNumber: "",
        managementMobilePhoneNumber: "",
        managementAddress: "",
        managementVillageId: "",
        managementPostalCode: "",
        managementDati2: "",
        cifCompanyManagementId: "",
      },
      area: {
        provinceId: "",
        cityId: "",
        districtId: "",
      },
      options: {
        jenisKelamin: [],
        province: [],
        city: [],
        district: [],
        village: [],
      },
      table: {
        data: {
          cifFamilies: [],
        },
      },
    };
  },
  methods: {
    routePageList() {
      this.$router.push("/cif");
    },
    async submitCifCompanyManagement() {
      if (!window.navigator.onLine) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: this.form.cifCompanyManagementId ? "PUT_DATA" : "POST_DATA",
          endPoint: "transaction",
          reqUrl: this.form.cifCompanyManagementId
            ? `cif-company-management/${this.form.cifCompanyManagementId}`
            : "cif-company-management",
          payload: this.form,
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
            this.closeModalCifCompanyManagement();
            this.getTableCifCompanyManagement();
          }
        }, 1500);
      } catch (error) {
        setTimeout(() => {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 1500);
      } finally {
        setTimeout(() => {
          this.property.animation.submitLoading = false;
        }, 1000);
      }
    },
    openModalCifCompanyManagement(payload) {
      this.form = {
        ...this.form,
        managementSequence: payload?.managementSequence,
        managementName: payload?.managementName,
        managementJobtitle: payload?.managementJobtitle,
        managementIdcardNumber: payload?.managementIdcardNumber,
        managementNpwpNumber: payload?.managementNpwpNumber,
        managementGenderId: payload?.managementGenderId,
        managementStock: payload?.managementStock,
        managementPhoneNumber: payload?.managementPhoneNumber,
        managementMobilePhoneNumber: payload?.managementMobilePhoneNumber,
        managementAddress: payload?.managementAddress,
        managementVillageId: payload?.mvillage?.villageId,
        managementPostalCode: payload?.managementPostalCode,
        managementDati2: payload?.managementDati2,
        cifCompanyManagementId: payload?.cifCompanyManagementId,
      };
      this.area = {
        provinceId: payload?.mvillage?.mdistrict?.mcity?.mprovince?.provinceId,
        cityId: payload?.mvillage?.mdistrict?.mcity?.cityId,
        districtId: payload?.mvillage?.mdistrict?.districtId,
      };
      this.onChangeProvince();
      this.property.modal.formCifCompanyManagement = true;
    },
    closeModalCifCompanyManagement() {
      this.form = {
        ...this.form,
        managementSequence: 0,
        managementName: "",
        managementJobtitle: "",
        managementIdcardNumber: "",
        managementNpwpNumber: "",
        managementGenderId: "",
        managementStock: 0,
        managementPhoneNumber: "",
        managementMobilePhoneNumber: "",
        managementAddress: "",
        managementVillageId: "",
        managementPostalCode: "",
        managementDati2: "",
        cifCompanyManagementId: "",
      };
      this.area = {
        provinceId: "",
        cityId: "",
        districtId: "",
      };
      this.options = {
        ...this.options,
        city: [],
        district: [],
        village: [],
      };
      this.property.modal.formCifCompanyManagement = false;
    },
    getCifById() {
      this.form = {
        ...this.form,
        cifId: this.cifEnterpriseDtoBuider().cifId,
      };
    },
    async getTableCifCompanyManagement() {
      this.table.data.cifFamilies = [];
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          this.property.listElement.downloading = true;
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "cif-company-management/cif-id/" + this.form.cifId,
          });
          console.log(resp);
          if (resp.data.code === "SUCCESS") {
            this.table.data.cifFamilies = resp.data.data;
          } else {
            this.property.listElement.message = resp.data.message;
          }
        } catch (error) {
          console.log(error.response);
          this.property.listElement.message = error.response
            ? `Gagal Mengunduh Data : ${error.response.statusText}`
            : "Terjadi Kesalahan";
        } finally {
          this.property.listElement.downloading = false;
        }
      }
    },
    async getReferenceGender() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "gender",
        });
        if (resp) {
          this.options.jenisKelamin = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((i) => {
            const value = i.genderId;
            const text = `${i.genderName}`.toUpperCase();
            this.options.jenisKelamin.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceProvince() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "area/province",
            params: {
              provinceName: "",
              page: 0,
            },
          }
        );
        if (resp) {
          this.options.province = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.content.map((i) => {
            const text = `${i.provinceName}`.toUpperCase();
            const value = i.provinceId;
            this.options.province.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async deleteCifCompanyManagement(payload) {
      if (this.validationDomain === 'pojok.bprshikp.co.id') {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Hapus Data Ini ?",
        type: "is-info",
        onConfirm: async () => {
          this.property.animation.submitLoading = true;
          try {
            const resp = await this.$store.dispatch({
              type: "DELETE_DATA",
              endPoint: "transaction",
              reqUrl:
                "cif-company-management/" + payload.cifCompanyManagementId,
            });
            setTimeout(() => {
              if (resp.data.code === "SUCCESS") {
                this.$buefy.snackbar.open(
                  this.$NotificationUtils.snackbarSuccess
                );
                setTimeout(() => {
                  this.getTableCifCompanyManagement();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            }, 1500);
          } catch (error) {
            setTimeout(() => {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }, 1500);
          } finally {
            setTimeout(() => {
              this.property.animation.submitLoading = false;
            }, 1000);
          }
        },
      });
    } else {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.$buefy.dialog.confirm({
        message: "Hapus Data Ini ?",
        type: "is-success",
        onConfirm: async () => {
          this.property.animation.submitLoading = true;
          try {
            const resp = await this.$store.dispatch({
              type: "DELETE_DATA",
              endPoint: "transaction",
              reqUrl:
                "cif-company-management/" + payload.cifCompanyManagementId,
            });
            setTimeout(() => {
              if (resp.data.code === "SUCCESS") {
                this.$buefy.snackbar.open(
                  this.$NotificationUtils.snackbarSuccess
                );
                setTimeout(() => {
                  this.getTableCifCompanyManagement();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            }, 1500);
          } catch (error) {
            setTimeout(() => {
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }, 1500);
          } finally {
            setTimeout(() => {
              this.property.animation.submitLoading = false;
            }, 1000);
          }
        },
      });
    }
    },
    async onChangeProvince() {
      this.options.city = [];
      this.options.district = [];
      this.options.village = [];
      this.form.managementPostalCode = "";
      const resp = await this.$store.dispatch(
        "GET_REFERENCE_FROM_MASTER_PARAMETER",
        {
          url: "area/city",
          params: {
            provinceId: this.area.provinceId,
            cityName: "",
            page: 0,
          },
        }
      );
      if (resp) {
        this.options.city = [{ text: "-- Pilih --", value: "" }];
        resp.data.data.content.map((i, index) => {
          const text = `${i.cityName}`.toUpperCase();
          const value = i.cityId;
          this.options.city.push({ text, value });
          if (index + 1 === resp.data.data.content.length) {
            if (this.area.cityId) {
              this.onChangeCity();
            }
          }
        });
      }
    },
    async onChangeCity() {
      this.options.district = [];
      this.options.village = [];
      this.form.managementPostalCode = "";
      const resp = await this.$store.dispatch(
        "GET_REFERENCE_FROM_MASTER_PARAMETER",
        {
          url: "area/district",
          params: {
            cityId: this.area.cityId,
            districtName: "",
            page: 0,
          },
        }
      );
      if (resp) {
        this.options.district = [{ text: "-- Pilih --", value: "" }];
        resp.data.data.content.map((i, index) => {
          const text = `${i.districtName}`.toUpperCase();
          const value = i.districtId;
          this.options.district.push({ text, value });
          if (index + 1 === resp.data.data.content.length) {
            if (this.area.cityId) {
              this.onChangeDistrct();
            }
          }
        });
      }
    },
    async onChangeDistrct() {
      this.options.village = [];
      this.form.managementPostalCode = "";
      const resp = await this.$store.dispatch(
        "GET_REFERENCE_FROM_MASTER_PARAMETER",
        {
          url: "area/village",
          params: {
            districtId: this.area.districtId,
            villageName: "",
            page: 0,
          },
        }
      );
      if (resp) {
        this.options.village = [{ text: "-- Pilih --", value: "" }];
        resp.data.data.content.map((i, index) => {
          const text = `${i.villageName}`.toUpperCase();
          const value = i.villageId;
          const postalcode = i.postalcode;
          this.options.village.push({ text, value, postalcode });
          if (index + 1 === resp.data.data.content.length) {
            if (this.area.districtId) {
              this.form.managementPostalCode = this.postalCodeFinder(
                this.options.village,
                this.form.managementVillageId
              );
            }
          }
        });
      }
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    this.getCifById();
    this.getTableCifCompanyManagement();
    this.getReferenceGender();
    this.getReferenceProvince();
  },
};
