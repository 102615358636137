import Mixins from "@/views/transactions/m-cif/mixins";
import html2pdf from "html2pdf.js";

export default {
  name: "CifPersonIdentity",
  props: {
    isExisting: false,
  },
  mixins: [Mixins],
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      isloading: false,
      property: {
        modal: {
          showModalPrintIdentityCif: false,
        },
      },
      form: {
        userRoleId: "",
        isTaxable: true,
        categoryId: "",
        cifNumber: "",
        isDomicileIdcard: false,
        nationalityCode: "",
        cifMotherName: "",
        cifPhoneNumber: "",
        cifEmail: "",
        classificationCode: "",
        cifIdName: "",
        cifSidName: "",
        cifRealName: "",
        birthDate: "",
        genderId: "",
        cifIdAddress: "",
        cifPostalCode: "",
        cifIdcardExpiredDate: "",
        cifNpwpNumber: "0",
        cardTypeCode: "",
        cifIdCardNumber: "",
        cifParentNumber: "",
        cifAliasName: "",
        birthPlace: "",
        villageId: "",
        isIdcardNeverExpired: true,
        cardPublisherId: "",
        maritalStatusId: "",
        religionId: "",
        cifDependentNumber: "",
        educationId: "",
        statusDegreeId: "",
        cifMobilePhoneNumber: "",
        promoAccountOfficerId: "",
        handleAccountOfficerId: "",
        groupOwnerCode: "",
        businessSegmentCode: "",
        companyRelatedCode: "",
        sandiLocationCode: "",
        regionalCode: "",
        cifDomicileAddress: "",
        statusDomicileId: "",
        cifBeneficiaryOwner: "",
        cifBeneficiaryRelation: "",
        cifBeneficiaryIdcardNumber: "",
        cifBeneficiaryFamilyName: "",
        cifBeneficiaryFamilyRelation: "",
        npwpReason: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",

        // print cif
        idcardTypeShortname: "",
        cifCategoryName: "",
        genderName: "",
        provinceName: "",
        cityName: "",
        districtName: "",
        villageName: "",
        idcardPublisherShortname: "",
        religionName: "",
        maritalStatusName: "",
        nationalityName: "",
        statusDegreeName: "",
        cifGroupOwnerName: "",
        businessSegmentName: "",
        cifCompanyRelatedName: "",
        cifClassificationName: "",
        cifSandiLocationName: "",
        cifStatusDomicileName: "",
        cifFamilyRelationName: "",
      },
      area: {
        provinceId: "",
        cityId: "",
        districtId: "",
      },
      options: {
        identitas: [],
        nomorNasabahInduk: [],
        jenisKelamin: [],
        provinsi: [],
        kotaKabupaten: [],
        kecamatan: [],
        kelurahan: [],
        masaBerlaku: [
          { value: true, text: "SEUMUR HIDUP" },
          { value: false, text: "TIDAK SEUMUR HIDUP" },
        ],
        penerbit: [],
        agama: [],
        statusPerkawinan: [],
        pendidikanTerakhir: [],
        statusGelar: [],
        aoPromosi: [],
        aoHandling: [],
        golonganPemilik: [],
        segmenBisnis: [],
        hubunganDenganBank: [],
        klasifikasiNasabah: [],
        sandiLokasi: [],
        kepemilikanRumah: [],
        hubungan: [],
        data: [],
      },
    };
  },
  methods: {
    async getAsyncData() {
      this.options.data = [];
      if (this.form.birthPlace === "") {
        return;
      }
      this.isloading = true;
      const resp = await this.$store.dispatch(
        "GET_REFERENCE_FROM_MASTER_PARAMETER",
        {
          url: "area/city",
          params: {
            provinceId: "",
            cityName: this.form.birthPlace,
            page: 0,
          },
        }
      );
      if (resp) {
        this.options.data = resp.data.data.content;
      }
      this.isloading = false;
    },
    onchhangeBirthplace() {
      // this.debounce(() => {
      //   console.log("hello");
      // });
      console.log("jell");
    },
    backToList() {
      this.$router.push("/cif");
    },
    submit() {
      this.form.birthDate = this.form.birthDate
        ? new Date(this.form.birthDate).toISOString()
        : "";
      this.form.cifIdcardExpiredDate = this.form.cifIdcardExpiredDate
        ? new Date(this.form.cifIdcardExpiredDate).toISOString()
        : "";
      this.$emit("submit", this.form);
    },
    getDefaultFormValue() {
      if (this.mixin_data_identifier_cif_data) {
        this.form = this.cifPersonDtoBuider();
        this.form.birthDate = this.form.birthDate
          ? this.dateToYearMonthDay(this.form.birthDate)
          : "";
        this.form.cifIdcardExpiredDate = this.form.cifIdcardExpiredDate
          ? this.dateToYearMonthDay(this.form.cifIdcardExpiredDate)
          : "";
        return;
      }
      var query = this.$route.query.refId;
      var data = JSON.parse(this.decryptBASE64(query));
      this.form.cifMotherName = data.cifMotherName;
      this.form.cifIdCardNumber = data.cifIdCardNumber;
      this.form.categoryId = this.fetchAppSession(
        "@vue-session/config-bundle"
      ).VUE_APP_CIF_CATEGORY_PERSON;
      this.setDefaultFormValue();
    },
    setDefaultFormValue() {
      this.form.nationalityCode = this.fetchAppSession(
        "@vue-session/config-bundle"
      ).VUE_APP_CIF_DEFAULT_PERSON_NATIONALITY;
      this.form.groupOwnerCode = this.fetchAppSession(
        "@vue-session/config-bundle"
      ).VUE_APP_CIF_DEFAULT_PERSON_GROUP_OWNER;
      this.form.businessSegmentCode = this.fetchAppSession(
        "@vue-session/config-bundle"
      ).VUE_APP_CIF_DEFAULT_PERSON_BUSINESS_SEGMENT;
      this.form.companyRelatedCode = this.fetchAppSession(
        "@vue-session/config-bundle"
      ).VUE_APP_CIF_DEFAULT_PERSON_COMPANY_RELATED;
      this.form.classificationCode = this.fetchAppSession(
        "@vue-session/config-bundle"
      ).VUE_APP_CIF_DEFAULT_PERSON_CLASIFICATION;
      this.form.sandiLocationCode = this.fetchAppSession(
        "@vue-session/config-bundle"
      ).VUE_APP_CIF_DEFAULT_PERSON_SANDI_LOCATION;
    },
    onChangeCifIdName() {
      this.form.cifRealName = this.form.cifIdName;
      this.form.cifAliasName = this.form.cifIdName;
    },
    async getReferenceIdCardType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "idcard-type",
          params: {
            cifCategoryId: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CIF_CATEGORY_PERSON,
          },
        });
        if (resp) {
          this.options.identitas = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((index) => {
            const text = `${index.idcardTypeShortname}`.toUpperCase();
            const value = index.idcardTypeCode;
            this.options.identitas.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceGender() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "gender",
        });
        if (resp) {
          this.options.jenisKelamin = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((i) => {
            const value = i.genderId;
            const text = `${i.genderName}`.toUpperCase();
            this.options.jenisKelamin.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceProvince() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "area/province",
            params: {
              provinceName: "",
              page: 0,
            },
          }
        );
        if (resp) {
          this.options.provinsi = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.content.map((i, index) => {
            const text = `${i.provinceName}`.toUpperCase();
            const value = i.provinceId;
            this.options.provinsi.push({ text, value });
            if (index + 1 === resp.data.data.content.length) {
              if (this.mixin_data_identifier_cif_data) {
                this.area.provinceId = this.mixin_data_identifier_cif_data?.villageId?.mdistrict?.mcity?.mprovince?.provinceId;
                this.onChangeProvince();
              }
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferencePublisher() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "idcard-publisher",
        });
        if (resp) {
          this.options.penerbit = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((index) => {
            const text = `${index.idcardPublisherShortname}`.toUpperCase();
            const value = index.idcardPublisherId;
            this.options.penerbit.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceRelegion() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_USER_MANAGEMENT",
          {
            url: "religion",
          }
        );
        if (resp) {
          this.options.agama = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((index) => {
            const text = `${index.religionName}`.toUpperCase();
            const value = index.religionId;
            this.options.agama.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceMaritalStatus() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_USER_MANAGEMENT",
          {
            url: "martial-status",
          }
        );
        if (resp) {
          this.options.statusPerkawinan = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((index) => {
            const text = `${index.maritalStatusName}`.toUpperCase();
            const value = index.maritalStatusId;
            this.options.statusPerkawinan.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceNationality() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "nationality",
          params: {
            cifCategoryId: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CIF_CATEGORY_PERSON,
          },
        });
        if (resp) {
          this.options.kewarganegaraan = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((i) => {
            const value = i.nationalityCode;
            const text = `${i.nationalityName}`.toUpperCase();
            this.options.kewarganegaraan.push({ value, text });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceEducation() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_USER_MANAGEMENT",
          {
            url: "education",
          }
        );
        if (resp) {
          this.options.pendidikanTerakhir = [
            { text: "-- Pilih --", value: "" },
          ];
          resp.data.data.map((i) => {
            const value = i.educationId;
            const text = `${i.educationName}`.toUpperCase();
            this.options.pendidikanTerakhir.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceStatusDegree() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "status-degree",
        });
        if (resp) {
          this.options.statusGelar = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((i) => {
            const value = i.statusDegreeId;
            const text = `${i.statusDegreeName}`.toUpperCase();
            this.options.statusGelar.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceUserAccountOfficer() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_USER_MANAGEMENT",
          {
            params: {
              page: 0,
            },
            url: "v2/user/active-user",
          }
        );
        if (resp) {
          resp.data.data.content.map((index) => {
            const text = `${index.profileSurename} - ${index.userNik}`;
            const value = index.userId;
            this.options.aoPromosi.push({ text, value });
            this.options.aoHandling.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceGroupOwner() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-group-owner",
        });
        if (resp) {
          this.options.golonganPemilik = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((i) => {
            const value = i.cifGroupOwnerCode;
            const text = `${i.cifGroupOwnerName}`.toUpperCase();
            this.options.golonganPemilik.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceBusinessSegment() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "business-segment",
            params: {
              name: "",
              page: 0,
            },
          }
        );
        if (resp) {
          this.options.segmenBisnis = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.content.map((i) => {
            const value = i.businessSegmentCode;
            const text = `${i.businessSegmentName}`.toUpperCase();
            this.options.segmenBisnis.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceCifCompanyRelated() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-company-related",
        });
        if (resp) {
          this.options.hubunganDenganBank = [
            { text: "-- Pilih --", value: "" },
          ];
          resp.data.data.map((i) => {
            const text = `${i.cifCompanyRelatedName}`.toUpperCase();
            const value = i.cifCompanyRelatedCode;
            this.options.hubunganDenganBank.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceCifClassification() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-classification",
        });
        if (resp) {
          this.options.klasifikasiNasabah = [
            { text: "-- Pilih --", value: "" },
          ];
          resp.data.data.map((i) => {
            const value = i.cifClassificationCode;
            const text = `${i.cifClassificationName}`.toUpperCase();
            this.options.klasifikasiNasabah.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceCifSandiLocation() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-sandi-location",
        });
        if (resp) {
          this.options.sandiLokasi = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((i) => {
            const text = `${i.cifSandiLocationName}`.toUpperCase();
            const value = i.cifSandiLocationCode;
            this.options.sandiLokasi.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceCifStatusDomicileLocation() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-status-domicile-location",
        });
        if (resp) {
          this.options.kepemilikanRumah = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((i) => {
            const text = `${i.cifStatusDomicileName}`.toUpperCase();
            const value = i.cifStatusDomicileId;
            this.options.kepemilikanRumah.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceCifFamily() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-family-relation",
        });
        if (resp) {
          this.options.hubungan = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((i) => {
            const value = i.cifFamilyRelationId;
            const text = i.cifFamilyRelationName;
            this.options.hubungan.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onChangeProvince() {
      this.options.kotaKabupaten = [];
      this.options.kecamatan = [];
      this.options.kelurahan = [];
      this.form.cifPostalCode = "";
      const resp = await this.$store.dispatch(
        "GET_REFERENCE_FROM_MASTER_PARAMETER",
        {
          url: "area/city",
          params: {
            provinceId: this.area.provinceId,
            cityName: "",
            page: 0,
          },
        }
      );
      if (resp) {
        this.options.kotaKabupaten = [{ text: "-- Pilih --", value: "" }];
        resp.data.data.content.map((i, index) => {
          const text = `${i.cityName}`.toUpperCase();
          const value = i.cityId;
          this.options.kotaKabupaten.push({ text, value });
          if (index + 1 === resp.data.data.content.length) {
            if (this.mixin_data_identifier_cif_data) {
              this.area.cityId = this.mixin_data_identifier_cif_data?.villageId?.mdistrict?.mcity?.cityId;
              this.onChangeCity();
            }
          }
        });
      }
    },
    async onChangeCity() {
      this.options.kecamatan = [];
      this.options.kelurahan = [];
      this.form.cifPostalCode = "";
      const resp = await this.$store.dispatch(
        "GET_REFERENCE_FROM_MASTER_PARAMETER",
        {
          url: "area/district",
          params: {
            cityId: this.area.cityId,
            districtName: "",
            page: 0,
          },
        }
      );
      if (resp) {
        this.options.kecamatan = [{ text: "-- Pilih --", value: "" }];
        resp.data.data.content.map((i, index) => {
          const text = `${i.districtName}`.toUpperCase();
          const value = i.districtId;
          this.options.kecamatan.push({ text, value });
          if (index + 1 === resp.data.data.content.length) {
            if (this.mixin_data_identifier_cif_data) {
              this.area.districtId = this.mixin_data_identifier_cif_data?.villageId?.mdistrict?.districtId;
              this.onChangeDistrct();
            }
          }
        });
      }
    },
    async onChangeDistrct() {
      this.options.kelurahan = [];
      this.form.cifPostalCode = "";
      const resp = await this.$store.dispatch(
        "GET_REFERENCE_FROM_MASTER_PARAMETER",
        {
          url: "area/village",
          params: {
            districtId: this.area.districtId,
            villageName: "",
            page: 0,
          },
        }
      );
      if (resp) {
        this.options.kelurahan = [{ text: "-- Pilih --", value: "" }];
        resp.data.data.content.map((i, index) => {
          const text = `${i.villageName}`.toUpperCase();
          const value = i.villageId;
          const postalcode = i.postalcode;
          this.options.kelurahan.push({ text, value, postalcode });
          if (index + 1 === resp.data.data.content.length) {
            if (this.mixin_data_identifier_cif_data) {
              this.form.cifPostalCode = this.mixin_data_identifier_cif_data?.cifPostalCode;
            }
          }
        });
      }
    },

    /* PRINT IDENTITY CIF */
    closePreview() {
      this.property.modal.showModalPrintIdentityCif = false;
    },
    downloadPdf() {
      this.property.modal.showModalPrintIdentityCif = true;
    },
    async onPressPrint() {
      this.isLoading = true;
      var element = document.getElementById("output-canvas");
      var opt = {
        // margin: 0.1,
        marginLeft: 0.5,
        marginRight: 0.5,
        marginBottom: 0,
        marginTop: 0,
        filename: `CIF_${this.form.cifIdName}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      await this.simpleWait(1000);
      this.isLoading = false;

      html2pdf()
        .set(opt)
        .from(element)
        .save();

      // New Promise-based usage:
      // html2pdf()
      //   .from(element)
      //   .set(opt)
      //   .toPdf()
      //   .get("pdf")
      //   .then((pdf) => {
      //     pdf.autoPrint();
      //     const hiddFrame = document.createElement("iframe");
      //     const isSafari = /^((?!chrome|android).)*safari/i.test(
      //       window.navigator.userAgent
      //     );
      //     if (isSafari) {
      //       // fallback in safari
      //       hiddFrame.onload = () => {
      //         try {
      //           hiddFrame.contentWindow.document.execCommand(
      //             "print",
      //             false,
      //             null
      //           );
      //         } catch (e) {
      //           hiddFrame.contentWindow.print();
      //         }
      //       };
      //     }
      //     hiddFrame.src = pdf.output("bloburl");
      //     document.body.appendChild(hiddFrame);
      //   });
    },
  },
  mounted() {
    this.getDefaultFormValue();
    this.getReferenceIdCardType();
    this.getReferenceGender();
    this.getReferenceProvince();
    this.getReferencePublisher();
    this.getReferenceRelegion();
    this.getReferenceMaritalStatus();
    this.getReferenceNationality();
    this.getReferenceEducation();
    this.getReferenceStatusDegree();
    this.getReferenceUserAccountOfficer();
    this.getReferenceGroupOwner();
    this.getReferenceBusinessSegment();
    this.getReferenceCifCompanyRelated();
    this.getReferenceCifCompanyRelated();
    this.getReferenceCifClassification();
    this.getReferenceCifSandiLocation();
    this.getReferenceCifStatusDomicileLocation();
    this.getReferenceCifFamily();
  },
};
