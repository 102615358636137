import Mixins from "@/views/transactions/m-cif/mixins";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "CifEnterpriseCompanyDocument",
  mixins: [Mixins],
  components: {
    PulseLoader,
  },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      property: {
        modal: {
          formCifCompanyDocument: false,
        },
        listElement: {
          downloading: false,
          massage: "",
        },
        animation: {
          submitLoading: false,
        },
      },
      form: {
        documentNotaryName: "",
        documentNumber: "",
        documentSubject: "",
        documentDueDate: "",
        description: "",
        cifCompanyDocumentId: "",
        cifId: "",
        rcifDocumentTypeId: "",
        createdDate: "",
      },
      table: {
        data: {
          cifCompanyDocument: [],
        },
      },
      options: {
        documentType: [],
      },
    };
  },
  methods: {
    backToList() {
      this.$router.push("/cif");
    },
    async submitCifCompanyDocument() {
      if (!window.navigator.onLine) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: this.form.cifCompanyDocumentId ? "PUT_DATA" : "POST_DATA",
          endPoint: "transaction",
          reqUrl: this.form.cifCompanyDocumentId
            ? `cif-company-document/${this.form.cifCompanyDocumentId}`
            : "cif-company-document",
          payload: this.form,
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
            this.closeModalCifCompanyDocument();
            this.getCifCompanyDocumentByCifId();
          }
        }, 1500);
      } catch (error) {
        setTimeout(() => {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 1500);
      } finally {
        setTimeout(() => {
          this.property.animation.submitLoading = false;
        }, 1000);
      }
    },
    openModalCifCompanyDocument(payload) {
      this.form = {
        ...this.form,
        documentNotaryName: payload?.documentNotaryName,
        documentNumber: payload?.documentNumber,
        documentSubject: payload?.documentSubject,
        documentDueDate: payload?.documentDueDate,
        description: payload?.description,
        rcifDocumentTypeId: payload?.rcifDocumentType.cifDocumentTypeId,
        cifCompanyDocumentId: payload?.cifCompanyDocumentId,
      };
      this.property.modal.formCifCompanyDocument = true;
    },
    async deleteCifCompanyDocument(payload) {
      if (this.validationDomain==='pojok.bprshikp.co.id') {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-info",
          onConfirm: async () => {
            this.property.animation.submitLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "cif-company-document/" + payload.cifCompanyDocumentId,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.snackbar.open(
                    this.$NotificationUtils.snackbarSuccess
                  );
                  setTimeout(() => {
                    this.getCifCompanyDocumentByCifId();
                  }, 1000);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `${resp.data.message}`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.submitLoading = false;
              }, 1000);
            }
          },
        });
      } else {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.submitLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "cif-company-document/" + payload.cifCompanyDocumentId,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.snackbar.open(
                    this.$NotificationUtils.snackbarSuccess
                  );
                  setTimeout(() => {
                    this.getCifCompanyDocumentByCifId();
                  }, 1000);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `${resp.data.message}`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.submitLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    closeModalCifCompanyDocument() {
      this.form = {
        ...this.form,
        documentNotaryName: "",
        documentNumber: "",
        documentSubject: "",
        documentDueDate: "",
        description: "",
        rcifDocumentTypeId: "",
        cifCompanyDocumentId: "",
      };
      this.options = {
        ...this.options,
        documentType: [],
      };
      this.property.modal.formCifCompanyDocument = false;
    },
    async getCifCompanyDocumentByCifId() {
      this.table.data.cifCompanyDocument = [];
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          this.property.listElement.downloading = true;
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "cif-company-document/cif-id/" + this.form.cifId,
          });
          console.log(resp);
          if (resp.data.code === "SUCCESS") {
            this.table.data.cifCompanyDocument = resp.data.data;
          } else {
            this.property.listElement.message = resp.data.message;
          }
        } catch (error) {
          console.log(error.response);
          this.property.listElement.message = error.response
            ? `Gagal Mengunduh Data : ${error.response.statusText}`
            : "Terjadi Kesalahan";
        } finally {
          this.property.listElement.downloading = false;
        }
      }
    },
    async getReferenceLegalEntity() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-document-type",
        });
        if (resp) {
          this.options.documentType = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((index) => {
            const text = index.documentTypeName;
            const value = index.cifDocumentTypeId;
            this.options.documentType.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    getCifById() {
      this.form = {
        ...this.form,
        cifId: this.cifEnterpriseDtoBuider().cifId,
      };
    },
    async getDate() {
      this.form.createdDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    this.getCifById();
    this.getCifCompanyDocumentByCifId();
    this.getReferenceLegalEntity();
    this.getDate();
  },
};
