import Mixins from "@/views/transactions/m-cif/mixins";

export default {
  name: "CifEnterpriseCorporate",
  mixins: [Mixins],
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      form: {
        cifCorporationProfitId: "",
        cifCorporationOwnerId: "",
        endorseeName1: "",
        endorseeName2: "",
        endorseeName3: "",
        endorseeTitle1: "",
        endorseeTitle2: "",
        endorseeTitle3: "",
        endorseeIdcard1: "",
        endorseeIdcard2: "",
        endorseeIdcard3: "",
        endorseeMobile1: "",
        endorseeMobile2: "",
        endorseeMobile3: "",
        mcifCorporationDocuments: [],
      },
      options: {
        cifCorporationOwner: [],
        cifCorporationProfit: [],
        cifDocumentType: [],
      },
    };
  },
  methods: {
    async getReferenceDocumentType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-document-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.documentTypeName;
            const value = index.cifDocumentTypeId;
            this.options.cifDocumentType.push({ text, value });
          });
        }
      // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    async getReferenceCorporationProfit() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-corporation-profit",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.cifCorporationProfitName;
            const value = index.cifCorporationProfitId;
            this.options.cifCorporationProfit.push({ text, value });
          });
        }
      // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    async getReferenceCorporationOwner() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-corporation-owner",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.cifCorporationOwnerName;
            const value = index.cifCorporationOwnerId;
            this.options.cifCorporationOwner.push({ text, value });
          });
        }
      // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    onInputCifDocumentType(item) {
      if (this.cifDocTypeValMapper(item)) {
        const unlessSelected = this.form.mcifCorporationDocuments.filter(
          (data) => data.cifDocumentTypeId !== item.value
        );
        this.form.mcifCorporationDocuments = unlessSelected;
        return;
      }
      this.form.mcifCorporationDocuments = [
        { cifDocumentTypeId: item.value },
        ...this.form.mcifCorporationDocuments,
      ];
    },
    cifDocTypeValMapper(item) {
      const data = this.form.mcifCorporationDocuments.find(
        (v) => v.cifDocumentTypeId === item.value
      );
      return data ? true : false;
    },
    async getCifCorporateById() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl:
            "/cif-corporation/cif-id/" + this.cifEnterpriseDtoBuider().cifId,
        });
        if (resp.data.code === "SUCCESS") {
          this.form = resp.data.data;
        }
      // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    async putCifCorporate() {
      if (this.validationDomain === 'pojok.bprshikp.co.id') {
      const confirmation = await this.simpleConfirmation(
        "Konfirmasi ?",
        "is-info"
      );
      if (!confirmation) {
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "transaction",
          reqUrl:
            "/cif-corporation/cif-id/" + this.cifEnterpriseDtoBuider().cifId,
          payload: this.form,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          return;
        }
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: resp.data.errorFields
            ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
            : resp.data.message,
          confirmText: "Ok",
          type: "is-danger",
        });
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    } else {
      const confirmation = await this.simpleConfirmation(
        "Konfirmasi ?",
        "is-success"
      );
      if (!confirmation) {
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "transaction",
          reqUrl:
            "/cif-corporation/cif-id/" + this.cifEnterpriseDtoBuider().cifId,
          payload: this.form,
        });
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
          return;
        }
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: resp.data.errorFields
            ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
            : resp.data.message,
          confirmText: "Ok",
          type: "is-danger",
        });
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    }
    },
    routePageList() {
      this.$router.push("/cif");
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    this.getCifCorporateById();
    this.getReferenceDocumentType();
    this.getReferenceCorporationOwner();
    this.getReferenceCorporationProfit();
  },
};
