import Mixins from "@/views/transactions/m-cif/mixins";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "CifEnterpriseCorportationField",
  mixins: [Mixins],
  components: {
    PulseLoader,
  },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: "",
      property: {
        modal: {
          formCifCorporationField: false,
        },
        listElement: {
          downloading: false,
          massage: "",
        },
        animation: {
          submitLoading: false,
        },
      },
      form: {
        cifId: "",
        cifProfessionFieldId: "",
      },
      table: {
        data: {
          cifCorporationField: [],
        },
      },
      options: {
        bidangUsaha: [],
      },
    };
  },
  methods: {
    backToList() {
      this.$router.push("/cif");
    },
    async submitCifCorporationField() {
      if (!window.navigator.onLine) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.submitLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: this.form.cifCorporationProfessionFieldId
            ? "PUT_DATA"
            : "POST_DATA",
          endPoint: "transaction",
          reqUrl: this.form.cifCorporationProfessionFieldId
            ? `cif-corporation-profession-field/${this.form.cifCorporationProfessionFieldId}`
            : "cif-corporation-profession-field",
          payload: this.form,
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
            this.closeModalCifCorporationField();
            this.getCifCorporationFieldByCifId();
          }
        }, 1500);
      } catch (error) {
        setTimeout(() => {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? `${error.response.statusText}`
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }, 1500);
      } finally {
        setTimeout(() => {
          this.property.animation.submitLoading = false;
        }, 1000);
      }
    },
    openModalCifCorporationField(payload) {
      console.log("payload", payload);
      this.form = {
        ...this.form,
        cifProfessionFieldId:
          payload?.rcifProfessionField?.cifProfessionFieldId,
      };
      this.property.modal.formCifCorporationField = true;
    },
    async deleteCifCorporationField(payload) {
      if (this.validationDomain==='pojok.bprshikp.co.id') {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-info",
          onConfirm: async () => {
            this.property.animation.submitLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl:
                  "cif-corporation-profession-field/" +
                  payload.cifCorporationProfessionFieldId,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.snackbar.open(
                    this.$NotificationUtils.snackbarSuccess
                  );
                  setTimeout(() => {
                    this.getCifCorporationFieldByCifId();
                  }, 1000);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `${resp.data.message}`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.submitLoading = false;
              }, 1000);
            }
          },
        });
      } else {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.submitLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl:
                  "cif-corporation-profession-field/" +
                  payload.cifCorporationProfessionFieldId,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.snackbar.open(
                    this.$NotificationUtils.snackbarSuccess
                  );
                  setTimeout(() => {
                    this.getCifCorporationFieldByCifId();
                  }, 1000);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `${resp.data.message}`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.submitLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    closeModalCifCorporationField() {
      this.form = {
        ...this.form,
        cifCorporationProfessionFieldId: "",
      };
      this.property.modal.formCifCorporationField = false;
    },
    async getReferenceCorporationField() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-profession-field",
        });
        if (resp) {
          this.options.bidangUsaha = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((index) => {
            const value = index.cifProfessionFieldId;
            const text = index.cifProfessionFieldName;
            this.options.bidangUsaha.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCifCorporationFieldByCifId() {
      this.table.data.cifCorporationField = [];
      try {
        this.property.listElement.downloading = true;
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_TRANSACTION",
          {
            params: {
              cifIdName: "",
              cifId: this.form.cifId,
              page: 0,
            },
            url: "cif-corporation-profession-field",
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.table.data.cifCorporationField = resp.data.data.content;
        } else {
          this.property.listElement.message = resp.data.message;
        }
      } catch (error) {
        this.property.listElement.message = error.response
          ? `Gagal Mengunduh Data : ${error.response.statusText}`
          : "Terjadi Kesalahan";
      } finally {
        this.property.listElement.downloading = false;
      }
    },
    getCifById() {
      this.form = {
        ...this.form,
        cifId: this.cifEnterpriseDtoBuider().cifId,
      };
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    this.getCifById();
    this.getCifCorporationFieldByCifId();
    this.getReferenceCorporationField();
  },
};
