<template>
  <div class="w-full">
    <div class="p-5" v-if="!isExisting">
      <Identity
        @submit="submitCifIdentity($event)"
        :isExisting="isExisting"
        ref="identityRef"
      />
    </div>

    <div v-if="validationDomain==='pojok.bprshikp.co.id'">
      <b-tabs destroy-on-hide v-if="isExisting" size="is-small" expanded>
        <b-tab-item label="DATA IDENTITAS">
          <Identity
            v-if="mixin_data_identifier_cif_data"
            @submit="submitCifIdentity($event)"
            :isExisting="isExisting"
            ref="identityRef"
          />
        </b-tab-item>
        <b-tab-item
          v-if="mixin_data_identifier_cif_data"
          label="DATA BIDANG USAHA"
        >
          <CorporationField />
        </b-tab-item>
        <b-tab-item
          v-if="mixin_data_identifier_cif_data"
          :isExisting="isExisting"
          label="DATA PENGURUS"
        >
          <CompanyManagement />
        </b-tab-item>
        <b-tab-item
          v-if="mixin_data_identifier_cif_data"
          label="DOKUMEN PERUSAHAAN"
        >
          <CompanyDocument />
        </b-tab-item>
        <b-tab-item
          v-if="mixin_data_identifier_cif_data"
          label="DATA KORPORASI"
        >
          <Corporate />
        </b-tab-item>
        <b-tab-item v-if="mixin_data_identifier_cif_data" label="DOKUMEN">
          <Document />
        </b-tab-item>
        <b-tab-item label="APUPPT">
          <Apuppt
            :isExisting="isExisting"
            @submit="submitCifApuppt($event)"
            v-if="mixin_data_identifier_cif_data"
          />
        </b-tab-item>
      </b-tabs>
    </div>
    <div v-else>
      <b-tabs
        destroy-on-hide
        v-if="isExisting"
        size="is-small"
        type="is-toggle"
        expanded
      >
        <b-tab-item label="DATA IDENTITAS">
          <Identity
            v-if="mixin_data_identifier_cif_data"
            @submit="submitCifIdentity($event)"
            :isExisting="isExisting"
            ref="identityRef"
          />
        </b-tab-item>
        <b-tab-item
          v-if="mixin_data_identifier_cif_data"
          label="DATA BIDANG USAHA"
        >
          <CorporationField />
        </b-tab-item>
        <b-tab-item
          v-if="mixin_data_identifier_cif_data"
          :isExisting="isExisting"
          label="DATA PENGURUS"
        >
          <CompanyManagement />
        </b-tab-item>
        <b-tab-item
          v-if="mixin_data_identifier_cif_data"
          label="DOKUMEN PERUSAHAAN"
        >
          <CompanyDocument />
        </b-tab-item>
        <b-tab-item
          v-if="mixin_data_identifier_cif_data"
          label="DATA KORPORASI"
        >
          <Corporate />
        </b-tab-item>
        <b-tab-item v-if="mixin_data_identifier_cif_data" label="DOKUMEN">
          <Document />
        </b-tab-item>
        <b-tab-item label="APUPPT">
          <Apuppt
            @submit="submitCifApuppt($event)"
            v-if="mixin_data_identifier_cif_data"
            :isExisting="isExisting"
          />
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Mixin from "@/views/transactions/m-cif/mixins";
import Identity from "./identity";
import CompanyManagement from "./company-management";
import CorporationField from "./corporation-field";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import CompanyDocument from "./company-document";
import Document from "./document";
import Corporate from "./corporate";
import Apuppt from "./apuppt";

export default {
  mixins: [Mixin],
  name: "FormCifEnterprise",
  data() {
    return {
      validationDomain: "",
      valPojok: process.env.VUE_APP_IS_POJOK,
    };
  },
  props: {
    isExisting: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Identity,
    CorporationField,
    CompanyManagement,
    // eslint-disable-next-line vue/no-unused-components
    ScaleLoader,
    CompanyDocument,
    Document,
    Corporate,
    Apuppt,
  },
  computed: {
    isLoading() {
      return this.isExisting
        ? this.mixin_data_identifier_cif_data
          ? false
          : true
        : false;
    },
  },
  methods: {
    submitCifIdentity(payload) {
      this.$emit("submitCifIdentity", payload);
    },
    submitCifApuppt(payload) {
      this.$emit("submitCifApuppt", payload);
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
  },
};
</script>
