import Mixins from "@/views/transactions/m-cif/mixins";

export default {
  name: "CifPersonProfession",
  mixins: [Mixins],
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      form: {
        professionId: "",
        professionFieldId: "",
        cifCompanyName: "",
        cifCompanyAddress: "",
        cifCompanyCity: "",
        cifCompanyPostalCode: "",
        cifCompanyPhoneNumber: "",
        cifCompanyFaxNumber: "",
        cifCompanyJobtitle: "",
        cifCompanyBoss: "",
        mainIncomeId: "",
        sideIncomeId: "",
        cifEmployeeNumber: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        pekerjaan: [],
        bidangUsahaPekerjaan: [],
        jumlahPenghasilan: [],
        penghasilanTambahan: [],
      },
    };
  },
  methods: {
    backToList() {
      this.$router.push("/cif");
    },
    async submit() {
      this.$emit("submit", this.form);
    },
    async getReferenceProfession() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-profession",
        });
        if (resp) {
          this.options.pekerjaan = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((index) => {
            const text = `${index.cifProfessionName}`.toUpperCase();
            const value = index.cifProfessionId;
            this.options.pekerjaan.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceProfessionField() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-profession-field",
        });
        if (resp) {
          this.options.bidangUsahaPekerjaan = [
            { text: "-- Pilih --", value: "" },
          ];
          resp.data.data.map((index) => {
            const text = `${index.cifProfessionFieldName}`.toUpperCase();
            const value = index.cifProfessionFieldId;
            this.options.bidangUsahaPekerjaan.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceCifIncome() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-income",
        });
        if (resp) {
          this.options.jumlahPenghasilan = [{ text: "-- Pilih --", value: "" }];
          this.options.penghasilanTambahan = [
            { text: "-- Pilih --", value: "" },
          ];
          resp.data.data.map((index) => {
            const text = `${index.cifIncomeName}`.toUpperCase();
            const value = index.cifIncomeId;
            this.options.jumlahPenghasilan.push({ text, value });
            this.options.penghasilanTambahan.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    buildDto() {
      this.form = this.cifPersonDtoBuider();
    },
  },
  mounted() {
    this.getReferenceProfession();
    this.getReferenceProfessionField();
    this.getReferenceCifIncome();
    this.buildDto();
  },
};
