<template>
  <div class="w-full">
    <FormCifPerson
      @submitCifIdentity="submitCifPersonIdentity($event)"
      @submitCifProfession="submitCifPersonProfession($event)"
      @submitCifApuppt="submitCifPersonApuppt($event)"
      ref="formCifPersonRef"
      :isExisting="isExisting"
      v-if="$route.params.type === 'person'"
    />
    <FormCifEnterprise
      :isExisting="isExisting"
      @submitCifIdentity="submitCifEnterPriseIdentity($event)"
      @submitCifApuppt="submitCifEnterPriseApuppt($event)"
      ref="formCifEnterPriseRef"
      v-else-if="$route.params.type === 'enterprise'"
    />
    <div v-else>
      <span>Bad Gateway</span>
    </div>
  </div>
</template>

<script>
import FormCifPerson from "./person";
import FormCifEnterprise from "./enterprise";

export default {
  name: "FormCif",
  components: {
    FormCifPerson,
    FormCifEnterprise,
  },
  props: {
    isExisting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      identity: {
        mainId: null,
      },
    };
  },
  methods: {
    submitCifPersonIdentity(payload) {
      this.$emit("submitCifPersonIdentity", payload);
    },
    submitCifPersonProfession(payload) {
      this.$emit("submitCifPersonProfession", payload);
    },
    submitCifPersonApuppt(payload) {
      this.$emit("submitCifPersonApuppt", payload);
    },
    submitCifEnterPriseIdentity(payload) {
      this.$emit("submitCifEnterPriseIdentity", payload);
    },
    submitCifEnterPriseApuppt(payload) {
      this.$emit("submitCifEnterPriseApuppt", payload);
    },
    backToList() {
      this.$router.push("/cif");
    },
    checkingMainId() {
      var params = this.$route.params.id;
      var query = this.$route.query.refId;
      if (!params && !query) {
        this.$buefy.toast.open(this.$NotificationUtils.idNotFound);
        this.backToList();
        return;
      }
      this.identity.mainId =
        this.isExisting === true
          ? this.decryptBASE64(params)
          : JSON.parse(this.decryptBASE64(query));
      this.$emit("getMainId", this.identity.mainId);
    },
  },
  mounted() {
    this.checkingMainId();
  },
  destroyed() {
    this.$store.dispatch("CLEAR_CIF_DATA");
  },
};
</script>
