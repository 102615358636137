import Mixins from "@/views/transactions/m-cif/mixins";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "CifPersonFamily",
  mixins: [Mixins],
  components: {
    PulseLoader,
  },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      property: {
        listElement: {
          downloading: false,
          message: "",
        },
        modal: {
          formCifFamily: false,
        },
        animation: {
          submitLoading: false,
        },
      },
      form: {
        cifDomicileAddress: false,
        cifId: "",
        cifFamilyId: "",
        cifFamilyRelationId: "",
        cifFamilyName: "",
        cifFamilyAddress: "",
        cifFamilyCity: "",
        birthDate: "",
        idcardNumber: "",
        phoneNumber: "",
        mobilePhoneNumber: "",
        //user-activity
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
        isCifFamilyAddress: false,
      },
      table: {
        data: {
          cifFamilies: [],
        },
      },
      options: {
        hubungan: [],
      },
    };
  },
  methods: {
    inputAddressCif() {
      if (this.form.isCifFamilyAddress === true) {
        this.form.cifFamilyAddress = this.mixin_data_identifier_cif_data.cifIdAddress;
      }
    },
    backToList() {
      this.$router.push("/cif");
    },
    async submitCifFamilly() {
      if (this.validationDomain==='pojok.bprshikp.co.id') {
        if (!window.navigator.onLine) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.property.animation.submitLoading = true;
        try {
          this.form.birthDate = this.form.birthDate
            ? this.dateToYearMonthDay(this.form.birthDate)
            : "";
          const resp = await this.$store.dispatch({
            type: this.form.cifFamilyId ? "PUT_DATA" : "POST_DATA",
            endPoint: "transaction",
            reqUrl: this.form.cifFamilyId
              ? `cif-family/${this.form.cifFamilyId}`
              : "cif-family",
            payload: this.form,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open({
                message: "Sukses !",
                type: "is-info",
                duration: 1000,
                position: "is-top",
                queue: false,
              });
              this.closeModalCifFamily();
              this.getCifFamilyByCifId();
            }
          }, 1500);
        } catch (error) {
          setTimeout(() => {
            console.log(error.response);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } finally {
          setTimeout(() => {
            this.property.animation.submitLoading = false;
          }, 1000);
        }
      } else {
        if (!window.navigator.onLine) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.property.animation.submitLoading = true;
        try {
          this.form.birthDate = this.form.birthDate
            ? this.dateToYearMonthDay(this.form.birthDate)
            : "";
          const resp = await this.$store.dispatch({
            type: this.form.cifFamilyId ? "PUT_DATA" : "POST_DATA",
            endPoint: "transaction",
            reqUrl: this.form.cifFamilyId
              ? `cif-family/${this.form.cifFamilyId}`
              : "cif-family",
            payload: this.form,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.$buefy.snackbar.open(
                this.$NotificationUtils.snackbarSuccess
              );
              this.closeModalCifFamily();
              this.getCifFamilyByCifId();
            }
          }, 1500);
        } catch (error) {
          setTimeout(() => {
            console.log(error.response);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } finally {
          setTimeout(() => {
            this.property.animation.submitLoading = false;
          }, 1000);
        }
      }
    },
    async deleteCifFamily(payload) {
      if (this.validationDomain==='pojok.bprshikp.co.id') {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-info",
          onConfirm: async () => {
            this.property.animation.submitLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "cif-family/" + payload.cifFamilyId,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open({
                    message: "Sukses !",
                    type: "is-info",
                    duration: 1000,
                    position: "is-top",
                    queue: false,
                  });
                  setTimeout(() => {
                    this.getCifFamilyByCifId();
                  }, 1000);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `${resp.data.message}`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.submitLoading = false;
              }, 1000);
            }
          },
        });
      } else {
        if (window.navigator.onLine === false) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.submitLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "cif-family/" + payload.cifFamilyId,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.snackbar.open(
                    this.$NotificationUtils.snackbarSuccess
                  );
                  setTimeout(() => {
                    this.getCifFamilyByCifId();
                  }, 1000);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `${resp.data.message}`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.submitLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    closeModalCifFamily() {
      this.form = {
        ...this.form,
        cifFamilyId: "",
        cifFamilyRelationId: "",
        cifFamilyName: "",
        cifFamilyAddress: "",
        cifFamilyCity: "",
        birthDate: "",
        idcardNumber: "",
        phoneNumber: "",
        mobilePhoneNumber: "",
      };
      this.property.modal.formCifFamily = false;
    },
    openModalCifFamily(payload) {
      this.form = {
        ...this.form,
        cifFamilyId: payload?.cifFamilyId,
        cifFamilyRelationId: payload?.cifFamilyRelationId,
        cifFamilyName: payload?.cifFamilyName,
        cifFamilyAddress: payload?.cifFamilyAddress,
        cifFamilyCity: payload?.cifFamilyCity,
        birthDate: payload
          ? payload.birthDate
            ? this.dateToYearMonthDay(payload.birthDate)
            : ""
          : "",
        idcardNumber: payload?.idcardNumber,
        phoneNumber: payload?.phoneNumber,
        mobilePhoneNumber: payload?.mobilePhoneNumber,

        createdByUserCode: payload?.createdByUserCode,
        createdByUserName: payload?.createdByUserName,
        createdDate: payload?.createdDate,
        updatedDate: payload?.updatedDate,
        updatedByUserCode: payload?.updatedByUserCode,
        updatedByUserName: payload?.updatedByUserName,
        authorizedDate: payload?.authorizedDate,
        authorizedByUserCode: payload?.authorizedByUserCode,
        authorizedByUserName: payload?.authorizedByUserName,
      };
      this.property.modal.formCifFamily = true;
    },
    async getReferenceFamillyRelation() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-family-relation",
        });
        if (resp) {
          this.options.hubungan = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((index) => {
            const value = index.cifFamilyRelationId;
            const text = index.cifFamilyRelationName;
            this.options.hubungan.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCifFamilyByCifId() {
      this.table.data.cifFamilies = [];
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          this.property.listElement.downloading = true;
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "cif-family/cif-id/" + this.form.cifId,
          });
          console.log(resp);
          if (resp.data.code === "SUCCESS") {
            this.table.data.cifFamilies = resp.data.data;
          } else {
            this.property.listElement.message = resp.data.message;
          }
        } catch (error) {
          console.log(error.response);
          this.property.listElement.message = error.response
            ? `Gagal Mengunduh Data : ${error.response.statusText}`
            : "Terjadi Kesalahan";
        } finally {
          this.property.listElement.downloading = false;
        }
      }
    },
    getCifById() {
      this.form = {
        ...this.form,
        cifId: this.cifPersonDtoBuider().cifId,
      };
    },
  },
  mounted() {
    this.getCifById();
    this.getReferenceFamillyRelation();
    this.getCifFamilyByCifId();
  },
};
