import UploadImage from "@/components/upload-image";
import { create } from "ipfs-http-client";
import Mixins from "@/views/transactions/m-cif/mixins";

export default {
  name: "CifEnterpriseDocument",
  mixins: [Mixins],
  components: {
    UploadImage,
  },
  data() {
    return {
      form: null,
      loading: false,
    };
  },
  methods: {
    backToList() {
      this.$router.push("/cif");
    },
    onUploadIdCardImage(event) {
      const ipfs = create(
        this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_DMS_STORAGE_URL
      );
      const data = event.imageData;
      const reader = new window.FileReader();
      reader.readAsArrayBuffer(data);
      reader.onload = async () => {
        var convertBuffer = Buffer(reader.result);
        const created = await ipfs.add(convertBuffer, {
          pin: false,
        });
        this.form.idcardImg = created.path;
        this.editCif();
      };
    },
    onUploadNpwpImage(event) {
      const ipfs = create(
        this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_DMS_STORAGE_URL
      );
      const data = event.imageData;
      const reader = new window.FileReader();
      reader.readAsArrayBuffer(data);
      reader.onload = async () => {
        var convertBuffer = Buffer(reader.result);
        const created = await ipfs.add(convertBuffer, {
          pin: false,
        });
        this.form.npwpImg = created.path;
        this.editCif();
      };
    },
    onDeleteIdCardImage() {
      this.form.idcardImg = null;
      this.editCif();
    },
    onDeleteNpwpImage() {
      this.form.npwpImg = null;
      this.editCif();
    },
    async editCif() {
      var getCifIdFromSession = this.cifEnterpriseDtoBuider().cifId;
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "transaction",
          reqUrl: "cif/cif-document/" + getCifIdFromSession,
          payload: {
            idcardImg: this.form.idcardImg,
            npwpImg: this.form.npwpImg,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open(this.$NotificationUtils.success);
            this.getCifById();
            this.updateDms(resp.data.data);
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        }, 1500);
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
      }
    },
    async getCifById() {
      this.loading = true;
      var getCifIdFromSession = this.cifEnterpriseDtoBuider().cifId;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "cif/cif-document/" + getCifIdFromSession,
        });
        if (resp.data.code === "SUCCESS") {
          this.form = resp.data.data;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1500);
      }
    },
    updateDms(data) {
      const ipfs = create(
        this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_DMS_STORAGE_URL
      );
      if (data.oldIdcardImg !== data.idcardImg) {
        if (data.idcardImg && !data.oldIdcardImg) {
          ipfs.pin.add(`${data.idcardImg}`);
        }
        if (data.idcardImg && data.oldIdcardImg) {
          ipfs.pin.rm(`${data.oldIdcardImg}`);
          ipfs.pin.add(`${data.idcardImg}`);
        }
        if (!data.idcardImg && data.oldIdcardImg) {
          ipfs.pin.rm(`${data.oldIdcardImg}`);
        }
      }
      if (data.oldNpwpImg !== data.npwpImg) {
        if (data.npwpImg && !data.oldNpwpImg) {
          ipfs.pin.add(`${data.npwpImg}`);
        }
        if (data.npwpImg && data.oldNpwpImg) {
          ipfs.pin.rm(`${data.oldNpwpImg}`);
          ipfs.pin.add(`${data.npwpImg}`);
        }
        if (!data.npwpImg && data.oldNpwpImg) {
          ipfs.pin.rm(`${data.oldNpwpImg}`);
        }
      }
    },
  },
  mounted() {
    this.getCifById();
  },
};
