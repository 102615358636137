<template>
  <div class="w-full">
    <div class="p-5" v-if="!isExisting">
      <Identity
        @submit="submitCifIdentity($event)"
        :isExisting="isExisting"
        ref="identityRef"
      />
    </div>
    <div v-if="validationDomain==='pojok.bprshikp.co.id'">
      <b-tabs destroy-on-hide v-if="isExisting" size="is-small" expanded>
        <b-tab-item label="DATA IDENTITAS">
          <Identity
            v-if="mixin_data_identifier_cif_data"
            @submit="submitCifIdentity($event)"
            :isExisting="isExisting"
            ref="identityRef"
          />
        </b-tab-item>
        <b-tab-item label="DATA PEKERJAAN">
          <Profession
            @submit="submitCifProfession($event)"
            v-if="mixin_data_identifier_cif_data"
          />
        </b-tab-item>
        <b-tab-item label="DATA KELUARGA">
          <Family v-if="mixin_data_identifier_cif_data" />
        </b-tab-item>
        <b-tab-item label="ALAMAT TAMBAHAN">
          <AdditionalAddress v-if="mixin_data_identifier_cif_data" />
        </b-tab-item>
        <b-tab-item label="UPLOAD DOKUMEN">
          <Document v-if="mixin_data_identifier_cif_data" />
        </b-tab-item>
        <b-tab-item label="APUPPT">
          <Apuppt
            @submit="submitCifApuppt($event)"
            v-if="mixin_data_identifier_cif_data"
          />
        </b-tab-item>
      </b-tabs>
    </div>
    <div v-else>
      <b-tabs
        destroy-on-hide
        v-if="isExisting"
        size="is-small"
        type="is-toggle"
        expanded
      >
        <b-tab-item label="DATA IDENTITAS">
          <Identity
            v-if="mixin_data_identifier_cif_data"
            @submit="submitCifIdentity($event)"
            :isExisting="isExisting"
            ref="identityRef"
          />
        </b-tab-item>
        <b-tab-item label="DATA PEKERJAAN">
          <Profession
            @submit="submitCifProfession($event)"
            v-if="mixin_data_identifier_cif_data"
          />
        </b-tab-item>
        <b-tab-item label="DATA KELUARGA">
          <Family v-if="mixin_data_identifier_cif_data" />
        </b-tab-item>
        <b-tab-item label="ALAMAT TAMBAHAN">
          <AdditionalAddress v-if="mixin_data_identifier_cif_data" />
        </b-tab-item>
        <b-tab-item label="UPLOAD DOKUMEN">
          <Document v-if="mixin_data_identifier_cif_data" />
        </b-tab-item>
        <b-tab-item label="APUPPT">
          <Apuppt
            @submit="submitCifApuppt($event)"
            v-if="mixin_data_identifier_cif_data"
          />
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Mixin from "@/views/transactions/m-cif/mixins";
import Identity from "./identity";
import Profession from "./profession";
import Family from "./family";
import Document from "./document";
import AdditionalAddress from "./additional-address";
import Apuppt from "./apuppt";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  mixins: [Mixin],
  name: "FormCifPerson",
  props: {
    isExisting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
    };
  },
  components: {
    Identity,
    Profession,
    Family,
    Document,
    AdditionalAddress,
    Apuppt,
    ScaleLoader,
  },
  computed: {
    isLoading() {
      return this.isExisting
        ? this.mixin_data_identifier_cif_data
          ? false
          : true
        : false;
    },
  },
  methods: {
    submitCifIdentity(payload) {
      this.$emit("submitCifIdentity", payload);
    },
    submitCifProfession(payload) {
      this.$emit("submitCifProfession", payload);
    },
    submitCifApuppt(payload) {
      this.$emit("submitCifApuppt", payload);
    },
  },
};
</script>
