export default {
  data() {
    return {
      MIXINS_DATA: {
        identifier: {
          cifData: this.$store.getters["get_identifier_cif_data"],
        },
      },
    };
  },
  computed: {
    mixin_data_identifier_cif_data() {
      return this.$store.getters["get_identifier_cif_data"];
    },
  },
  methods: {
    async mixins_getCifById(payload) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$store.dispatch("CLEAR_CIF_DATA");
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "cif/" + payload,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.$store.dispatch("SET_IDENTIFIER_CIF_DATA", resp.data.data);
            }
          }, 1000);
        } catch (error) {
          console.log(error);
        }
      }
    },
    cifPersonDtoBuider() {
      const data = {
        isTaxable: this.mixin_data_identifier_cif_data?.isTaxable,
        cifId: this.mixin_data_identifier_cif_data?.cifId,
        categoryId: this.mixin_data_identifier_cif_data?.categoryId
          ?.cifCategoryId,
        cifNumber: this.mixin_data_identifier_cif_data?.cifNumber,
        isDomicileIdcard: this.mixin_data_identifier_cif_data?.isDomicileIdcard,
        nationalityCode: this.mixin_data_identifier_cif_data?.nationalityId
          ?.nationalityCode,
        cifMotherName: this.mixin_data_identifier_cif_data?.cifMotherName,
        cifPhoneNumber: this.mixin_data_identifier_cif_data?.cifPhoneNumber,
        cifEmail: this.mixin_data_identifier_cif_data?.cifEmail,
        classificationCode: this.mixin_data_identifier_cif_data
          ?.classificationId?.cifClassificationCode,
        cifIdName: this.mixin_data_identifier_cif_data?.cifIdName,
        cifSidName: this.mixin_data_identifier_cif_data?.cifSidName,
        cifRealName: this.mixin_data_identifier_cif_data?.cifRealName,
        birthDate: this.mixin_data_identifier_cif_data?.birthDate,
        genderId: this.mixin_data_identifier_cif_data?.genderId?.genderId,
        cifIdAddress: this.mixin_data_identifier_cif_data?.cifIdAddress,
        cifPostalCode: this.mixin_data_identifier_cif_data?.cifPostalCode,
        cifIdcardExpiredDate: this.mixin_data_identifier_cif_data
          ?.cifIdcardExpiredDate,
        cifNpwpNumber: this.mixin_data_identifier_cif_data?.cifNpwpNumber,
        npwpReason: this.mixin_data_identifier_cif_data?.npwpReason,
        cardTypeCode: this.mixin_data_identifier_cif_data?.cardTypeId
          ?.idcardTypeCode,
        cifIdCardNumber: this.mixin_data_identifier_cif_data?.cifIdcardNumber,
        cifParentNumber: this.mixin_data_identifier_cif_data?.cifParentNumber,
        cifAliasName: this.mixin_data_identifier_cif_data?.cifAliasName,
        birthPlace: this.mixin_data_identifier_cif_data?.birthPlace,
        villageId: this.mixin_data_identifier_cif_data?.villageId?.villageId,
        isIdcardNeverExpired: this.mixin_data_identifier_cif_data
          ?.isIdcardNeverExpired,
        cardPublisherId: this.mixin_data_identifier_cif_data?.publisherId
          ?.idcardPublisherId,
        maritalStatusId: this.mixin_data_identifier_cif_data?.maritalStatusId
          ?.maritalStatusId,
        religionId: this.mixin_data_identifier_cif_data?.religionId?.religionId,
        cifDependentNumber: this.mixin_data_identifier_cif_data
          ?.cifDependentNumber,
        educationId: this.mixin_data_identifier_cif_data?.educationId
          ?.educationId,
        statusDegreeId: this.mixin_data_identifier_cif_data?.statusDegreeId
          ?.statusDegreeId,
        cifMobilePhoneNumber: this.mixin_data_identifier_cif_data
          ?.cifMobilePhoneNumber,
        promoAccountOfficerId: this.mixin_data_identifier_cif_data
          ?.promoAccountOfficerId,
        handleAccountOfficerId: this.mixin_data_identifier_cif_data
          ?.handleAccountOfficerId,
        groupOwnerCode: this.mixin_data_identifier_cif_data?.groupOwnerId
          ?.cifGroupOwnerCode,
        businessSegmentCode: this.mixin_data_identifier_cif_data
          ?.businessSegmentId?.businessSegmentCode,
        companyRelatedCode: this.mixin_data_identifier_cif_data
          ?.companyRelatedId?.cifCompanyRelatedCode,
        sandiLocationCode: this.mixin_data_identifier_cif_data?.sandiLocationId
          ?.cifSandiLocationCode,
        regionalCode: this.mixin_data_identifier_cif_data?.regionalCode,
        cifDomicileAddress: this.mixin_data_identifier_cif_data
          ?.cifDomicileAddress,
        statusDomicileId: this.mixin_data_identifier_cif_data?.statusDomicileId
          ?.cifStatusDomicileId,
        cifBeneficiaryOwner: this.mixin_data_identifier_cif_data
          ?.cifBeneficiaryOwner,
        cifBeneficiaryRelation: this.mixin_data_identifier_cif_data
          ?.cifBeneficiaryRelation,
        cifBeneficiaryIdcardNumber: this.mixin_data_identifier_cif_data
          ?.cifBeneficiaryIdcardNumber,
        cifBeneficiaryFamilyName: this.mixin_data_identifier_cif_data
          ?.cifBeneficiaryFamilyName,
        cifBeneficiaryFamilyRelation: this.mixin_data_identifier_cif_data
          ?.cifBeneficiaryFamilyRelation,
        professionId: this.mixin_data_identifier_cif_data?.professionId
          ?.cifProfessionId,
        professionFieldId: this.mixin_data_identifier_cif_data
          ?.professionFieldId?.cifProfessionFieldId,
        cifCompanyName: this.mixin_data_identifier_cif_data?.cifCompanyName,
        cifCompanyAddress: this.mixin_data_identifier_cif_data
          ?.cifCompanyAddress,
        cifCompanyCity: this.mixin_data_identifier_cif_data?.cifCompanyCity,
        cifCompanyPostalCode: this.mixin_data_identifier_cif_data
          ?.cifCompanyPostalCode,
        cifCompanyPhoneNumber: this.mixin_data_identifier_cif_data
          ?.cifCompanyPhoneNumber,
        cifCompanyFaxNumber: this.mixin_data_identifier_cif_data
          ?.cifCompanyFaxNumber,
        cifCompanyJobtitle: this.mixin_data_identifier_cif_data
          ?.cifCompanyJobtitle,
        cifCompanyBoss: this.mixin_data_identifier_cif_data?.cifCompanyBoss,
        mainIncomeId: this.mixin_data_identifier_cif_data?.mainIncomeId
          ?.cifIncomeId,
        sideIncomeId: this.mixin_data_identifier_cif_data?.sideIncomeId
          ?.cifIncomeId,
        cifEmployeeNumber: this.mixin_data_identifier_cif_data
          ?.cifEmployeeNumber,
        createdByUserCode: this.mixin_data_identifier_cif_data
          ?.createdByUserCode,
        createdByUserName: this.mixin_data_identifier_cif_data
          ?.createdByUserName,
        createdDate: this.mixin_data_identifier_cif_data?.createdDate,
        updatedDate: this.mixin_data_identifier_cif_data?.updatedDate,
        updatedByUserCode: this.mixin_data_identifier_cif_data
          ?.updatedByUserCode,
        updatedByUserName: this.mixin_data_identifier_cif_data
          ?.updatedByUserName,
        authorizedDate: this.mixin_data_identifier_cif_data?.authorizedDate,
        authorizedByUserCode: this.mixin_data_identifier_cif_data
          ?.authorizedByUserCode,
        authorizedByUserName: this.mixin_data_identifier_cif_data
          ?.authorizedByUserName,

        /*Print Cif*/
        cifCategoryName: this.mixin_data_identifier_cif_data?.categoryId
          ?.cifCategoryName,
        idcardTypeShortname: this.mixin_data_identifier_cif_data?.cardTypeId
          ?.idcardTypeShortname,
        genderName: this.mixin_data_identifier_cif_data?.genderId?.genderName,
        provinceName: this.mixin_data_identifier_cif_data?.villageId?.mdistrict
          ?.mcity?.mprovince?.provinceName,
        cityName: this.mixin_data_identifier_cif_data?.villageId?.mdistrict
          ?.mcity?.cityName,
        districtName: this.mixin_data_identifier_cif_data?.villageId?.mdistrict
          ?.districtName,
        villageName: this.mixin_data_identifier_cif_data?.villageId
          ?.villageName,
        idcardPublisherShortname: this.mixin_data_identifier_cif_data
          ?.publisherId?.idcardPublisherShortname,
        religionName: this.mixin_data_identifier_cif_data?.religionId
          ?.religionName,
        maritalStatusName: this.mixin_data_identifier_cif_data?.maritalStatusId
          ?.maritalStatusName,
        nationalityName: this.mixin_data_identifier_cif_data?.nationalityId
          ?.nationalityName,
        educationName: this.mixin_data_identifier_cif_data?.educationId
          ?.educationName,
        statusDegreeName: this.mixin_data_identifier_cif_data?.statusDegreeId
          ?.statusDegreeName,
        cifGroupOwnerName: this.mixin_data_identifier_cif_data?.groupOwnerId
          ?.cifGroupOwnerName,
        businessSegmentName: this.mixin_data_identifier_cif_data
          ?.businessSegmentId?.businessSegmentName,
        cifCompanyRelatedName: this.mixin_data_identifier_cif_data
          ?.companyRelatedId?.cifCompanyRelatedName,
        cifClassificationName: this.mixin_data_identifier_cif_data
          ?.classificationId?.cifClassificationName,
        cifSandiLocationName: this.mixin_data_identifier_cif_data
          ?.sandiLocationId?.cifSandiLocationName,
        cifStatusDomicileName: this.mixin_data_identifier_cif_data
          ?.statusDomicileId?.cifStatusDomicileName,
        cifFamilyRelationName: this.mixin_data_identifier_cif_data
          ?.cifFamilyRelationName,
        customerIdentity: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.customerIdentity,
        businessLocation: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.businessLocation,
        customerProfile: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.customerProfile,
        transactionAmount: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.transactionAmount,
        ownershipStructure: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.ownershipStructure,
        businessActivities: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.businessActivities,
        other: this.mixin_data_identifier_cif_data?.rprofileRiskDto?.other,
        decision: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.decision,
        riskLevelCriteria: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.riskLevelCriteria,
        userRoleId: this.fetchAppSession("@vue-session/auth-bundle").roleId,
      };
      return data;
    },
    cifEnterpriseDtoBuider() {
      const data = {
        isTaxable: this.mixin_data_identifier_cif_data?.isTaxable,
        cifId: this.mixin_data_identifier_cif_data?.cifId,
        cifIdCardNumber: this.mixin_data_identifier_cif_data?.cifIdcardNumber,
        cifNumber: this.mixin_data_identifier_cif_data?.cifNumber,
        legalEntityId: this.mixin_data_identifier_cif_data?.legalEntityId
          ?.legalEntityId,
        cifIdName: this.mixin_data_identifier_cif_data?.cifIdName,
        cifSidName: this.mixin_data_identifier_cif_data?.cifSidName,
        cifRealName: this.mixin_data_identifier_cif_data?.cifRealName,
        birthPlace: this.mixin_data_identifier_cif_data?.birthPlace,
        birthDate: this.mixin_data_identifier_cif_data?.birthDate,
        cifIdAddress: this.mixin_data_identifier_cif_data?.cifIdAddress,
        cifPostalCode: this.mixin_data_identifier_cif_data?.cifPostalCode,
        cifNpwpNumber: this.mixin_data_identifier_cif_data?.cifNpwpNumber,
        nationalityCode: this.mixin_data_identifier_cif_data?.nationalityId
          ?.nationalityCode,
        cifPhoneNumber: this.mixin_data_identifier_cif_data?.cifPhoneNumber,
        cifMobilePhoneNumber: this.mixin_data_identifier_cif_data
          ?.cifMobilePhoneNumber,
        cifEmail: this.mixin_data_identifier_cif_data?.cifEmail,
        promoAccountOfficerId: this.mixin_data_identifier_cif_data
          ?.promoAccountOfficerId,
        handleAccountOfficerId: this.mixin_data_identifier_cif_data
          ?.handleAccountOfficerId,
        groupOwnerCode: this.mixin_data_identifier_cif_data?.groupOwnerId
          ?.cifGroupOwnerCode,
        businessSegmentCode: this.mixin_data_identifier_cif_data
          ?.businessSegmentId?.businessSegmentCode,
        companyRelatedCode: this.mixin_data_identifier_cif_data
          ?.companyRelatedId?.cifCompanyRelatedCode,
        classificationCode: this.mixin_data_identifier_cif_data
          ?.classificationId?.cifClassificationCode,
        sandiLocationCode: this.mixin_data_identifier_cif_data?.sandiLocationId
          ?.cifSandiLocationCode,
        regionalCode: this.mixin_data_identifier_cif_data?.regionalCode,
        statusDomicileId: this.mixin_data_identifier_cif_data?.statusDomicileId
          ?.cifStatusDomicileId,
        isDomicileIdcard: this.mixin_data_identifier_cif_data?.isDomicileIdcard,
        villageId: this.mixin_data_identifier_cif_data?.villageId?.villageId,
        categoryId: this.mixin_data_identifier_cif_data?.categoryId
          ?.cifCategoryId,
        cardTypeCode: this.mixin_data_identifier_cif_data?.cardTypeId
          ?.idcardTypeCode,
        officeId: this.mixin_data_identifier_cif_data?.officeId?.officeId,
        cifBeneficiaryOwner: this.mixin_data_identifier_cif_data
          ?.cifBeneficiaryOwner,
        cifBeneficiaryRelation: this.mixin_data_identifier_cif_data
          ?.cifBeneficiaryRelation,
        cifBeneficiaryIdcardNumber: this.mixin_data_identifier_cif_data
          ?.cifBeneficiaryIdcardNumber,
        cifBeneficiaryFamilyName: this.mixin_data_identifier_cif_data
          ?.cifBeneficiaryFamilyName,
        cifBeneficiaryFamilyRelation: this.mixin_data_identifier_cif_data
          ?.cifBeneficiaryFamilyRelation,
        cifDomicileAddress: this.mixin_data_identifier_cif_data
          ?.cifDomicileAddress,
        createdByUserCode: this.mixin_data_identifier_cif_data
          ?.createdByUserCode,
        createdByUserName: this.mixin_data_identifier_cif_data
          ?.createdByUserName,
        createdDate: this.mixin_data_identifier_cif_data?.createdDate,
        updatedDate: this.mixin_data_identifier_cif_data?.updatedDate,
        updatedByUserCode: this.mixin_data_identifier_cif_data
          ?.updatedByUserCode,
        updatedByUserName: this.mixin_data_identifier_cif_data
          ?.updatedByUserName,
        authorizedDate: this.mixin_data_identifier_cif_data?.authorizedDate,
        authorizedByUserCode: this.mixin_data_identifier_cif_data
          ?.authorizedByUserCode,
        authorizedByUserName: this.mixin_data_identifier_cif_data
          ?.authorizedByUserName,

        /* Print */
        cifCategoryName: this.mixin_data_identifier_cif_data?.categoryId
          ?.cifCategoryName,
        idcardTypeShortname: this.mixin_data_identifier_cif_data?.cardTypeId
          ?.idcardTypeShortname,
        legalEntityName: this.mixin_data_identifier_cif_data?.legalEntityId
          ?.legalEntityName,
        provinceName: this.mixin_data_identifier_cif_data?.villageId?.mdistrict
          ?.mcity?.mprovince?.provinceName,
        cityName: this.mixin_data_identifier_cif_data?.villageId?.mdistrict
          ?.mcity?.cityName,
        districtName: this.mixin_data_identifier_cif_data?.villageId?.mdistrict
          ?.districtName,
        villageName: this.mixin_data_identifier_cif_data?.villageId
          ?.villageName,
        nationalityName: this.mixin_data_identifier_cif_data?.nationalityId
          ?.nationalityName,
        cifGroupOwnerName: this.mixin_data_identifier_cif_data?.groupOwnerId
          ?.cifGroupOwnerName,
        businessSegmentName: this.mixin_data_identifier_cif_data
          ?.businessSegmentId?.businessSegmentName,
        cifCompanyRelatedName: this.mixin_data_identifier_cif_data
          ?.companyRelatedId?.cifCompanyRelatedName,
        cifClassificationName: this.mixin_data_identifier_cif_data
          ?.classificationId?.cifClassificationName,
        cifSandiLocationName: this.mixin_data_identifier_cif_data
          ?.sandiLocationId?.cifSandiLocationName,
        cifStatusDomicileName: this.mixin_data_identifier_cif_data
          ?.statusDomicileId?.cifStatusDomicileName,
        cifFamilyRelationName: this.mixin_data_identifier_cif_data
          ?.cifFamilyRelationName,

        customerIdentity: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.customerIdentity,
        businessLocation: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.businessLocation,
        customerProfile: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.customerProfile,
        transactionAmount: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.transactionAmount,
        ownershipStructure: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.ownershipStructure,
        businessActivities: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.businessActivities,
        other: this.mixin_data_identifier_cif_data?.rprofileRiskDto?.other,
        decision: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.decision,
        riskLevelCriteria: this.mixin_data_identifier_cif_data?.rprofileRiskDto
          ?.riskLevelCriteria,
        userRoleId: this.fetchAppSession("@vue-session/auth-bundle").roleId,
        npwpReason: this.mixin_data_identifier_cif_data?.npwpReason,
      };
      return data;
    },
  },
};
