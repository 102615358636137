import Mixins from "@/views/transactions/m-cif/mixins";
import html2pdf from "html2pdf.js";

export default {
  name: "CifEnterpriseIdentity",
  props: {
    isExisting: false,
  },
  mixins: [Mixins],
  data() {
    return {
      valPojok: process.env.VUE_APP_IS_POJOK,
      property: {
        animation: {
          modal: {
            showModalPrintIdentityCif: false,
          },
        },
      },
      form: {
        userRoleId: "",
        validationDomain: "",
        isTaxable: true,
        cifId: "",
        appendAkte: "",
        cifIdCardNumber: "",
        legalEntityId: "",
        cifIdName: "",
        cifSidName: "",
        cifRealName: "",
        birthPlace: "",
        birthDate: "",
        cifIdAddress: "",
        cifPostalCode: "",
        cifNpwpNumber: "0",
        nationalityCode: "",
        cifPhoneNumber: "",
        cifMobilePhoneNumber: "",
        cifEmail: "",
        promoAccountOfficerId: "",
        handleAccountOfficerId: "",
        groupOwnerCode: "",
        businessSegmentCode: "",
        companyRelatedCode: "",
        classificationCode: "",
        sandiLocationCode: "",
        regionalCode: "",
        statusDomicileId: "",
        isDomicileIdcard: false,
        villageId: "",
        categoryId: "",
        cardTypeCode: "",
        cifBeneficiaryOwner: "",
        cifBeneficiaryRelation: "",
        cifBeneficiaryIdcardNumber: "",
        cifBeneficiaryFamilyName: "",
        cifBeneficiaryFamilyRelation: "",
        cifDomicileAddress: "",
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
        npwpReason: "",

        /* Print */
        cifCategoryName: "",
        idcardTypeShortname: "",
        legalEntityName: "",
        provinceName: "",
        cityName: "",
        districtName: "",
        villageName: "",
        nationalityName: "",
        cifGroupOwnerName: "",
        businessSegmentName: "",
        cifCompanyRelatedName: "",
        cifClassificationName: "",
        cifSandiLocationName: "",
        cifStatusDomicileName: "",
      },
      area: {
        provinceId: "",
        cityId: "",
        districtId: "",
      },
      options: {
        provinsi: [],
        kotaKabupaten: [],
        kecamatan: [],
        kelurahan: [],
        aoPromosi: [],
        aoHandling: [],
        golonganPemilik: [],
        segmenBisnis: [],
        hubunganDenganBank: [],
        klasifikasiNasabah: [],
        sandiLokasi: [],
        kepemilikanKantor: [],
        hubungan: [],
        bentukBadanHukum: [],
        kantor: [],
        identitas: [],
        typePerusahaan: [
          { value: "", text: "-- Pilih --" },
          { value: "4", text: "Non-Bank" },
          { value: "3", text: "Bank" },
        ],
      },
    };
  },
  methods: {
    backToList() {
      this.$router.push("/cif");
    },
    getDefaultFormValue() {
      if (this.mixin_data_identifier_cif_data) {
        this.form = this.cifEnterpriseDtoBuider();
        this.form.birthDate = this.form.birthDate
          ? this.dateToYearMonthDay(this.form.birthDate)
          : "";
        this.form.cifIdcardExpiredDate = this.form.cifIdcardExpiredDate
          ? this.dateToYearMonthDay(this.form.cifIdcardExpiredDate)
          : "";
        return;
      }
      var query = this.$route.query.refId;
      var data = JSON.parse(this.decryptBASE64(query));
      this.form.cifIdCardNumber = data.cifIdCardNumber;
      this.form.categoryId = this.fetchAppSession(
        "@vue-session/config-bundle"
      ).VUE_APP_CIF_CATEGORY_COMPANY;
    },
    onChangeCifIdName() {
      this.form.cifSidName = this.form.cifIdName;
      this.form.cifRealName = this.form.cifIdName;
      this.form.cifAliasName = this.form.cifIdName;
    },
    async getReferenceCifFamily() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-family-relation",
        });
        if (resp) {
          this.options.hubungan = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((i) => {
            const value = i.cifFamilyRelationId;
            const text = i.cifFamilyRelationName;
            this.options.hubungan.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceIdCardType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "idcard-type",
          params: {
            cifCategoryId: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CIF_CATEGORY_COMPANY,
          },
        });
        if (resp) {
          this.options.identitas = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((index) => {
            const text = `${index.idcardTypeShortname}`.toUpperCase();
            const value = index.idcardTypeCode;
            this.options.identitas.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceLegalEntity() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-legal-entity",
        });
        if (resp) {
          this.options.bentukBadanHukum = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((index) => {
            const text = `${index.legalEntityName}`.toUpperCase();
            const value = index.legalEntityId;
            this.options.bentukBadanHukum.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    submit() {
      this.form.birthDate = this.form.birthDate
        ? new Date(this.form.birthDate).toISOString()
        : "";
      this.form.cifIdcardExpiredDate = this.form.cifIdcardExpiredDate
        ? new Date(this.form.cifIdcardExpiredDate).toISOString()
        : "";
      this.$emit("submit", this.form);
    },
    async onChangeProvince() {
      this.options.kotaKabupaten = [];
      this.options.kecamatan = [];
      this.options.kelurahan = [];
      this.form.cifPostalCode = "";
      const resp = await this.$store.dispatch(
        "GET_REFERENCE_FROM_MASTER_PARAMETER",
        {
          url: "area/city",
          params: {
            provinceId: this.area.provinceId,
            cityName: "",
            page: 0,
          },
        }
      );
      if (resp) {
        this.options.kotaKabupaten = [{ text: "-- Pilih --", value: "" }];
        resp.data.data.content.map((i, index) => {
          const text = `${i.cityName}`.toUpperCase();
          const value = i.cityId;
          this.options.kotaKabupaten.push({ text, value });
          if (index + 1 === resp.data.data.content.length) {
            if (this.mixin_data_identifier_cif_data) {
              this.area.cityId = this.mixin_data_identifier_cif_data?.villageId?.mdistrict?.mcity?.cityId;
              this.onChangeCity();
            }
          }
        });
      }
    },
    async onChangeCity() {
      this.options.kecamatan = [];
      this.options.kelurahan = [];
      this.form.cifPostalCode = "";
      const resp = await this.$store.dispatch(
        "GET_REFERENCE_FROM_MASTER_PARAMETER",
        {
          url: "area/district",
          params: {
            cityId: this.area.cityId,
            districtName: "",
            page: 0,
          },
        }
      );
      if (resp) {
        this.options.kecamatan = [{ text: "-- Pilih --", value: "" }];
        resp.data.data.content.map((i, index) => {
          const text = `${i.districtName}`.toUpperCase();
          const value = i.districtId;
          this.options.kecamatan.push({ text, value });
          if (index + 1 === resp.data.data.content.length) {
            if (this.mixin_data_identifier_cif_data) {
              this.area.districtId = this.mixin_data_identifier_cif_data?.villageId?.mdistrict?.districtId;
              this.onChangeDistrct();
            }
          }
        });
      }
    },
    async getReferenceCifStatusDomicileLocation() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-status-domicile-location",
        });
        if (resp) {
          this.options.kepemilikanKantor = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((i) => {
            const text = `${i.cifStatusDomicileName}`.toUpperCase();
            const value = i.cifStatusDomicileId;
            this.options.kepemilikanKantor.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onChangeDistrct() {
      this.options.kelurahan = [];
      this.form.cifPostalCode = "";
      const resp = await this.$store.dispatch(
        "GET_REFERENCE_FROM_MASTER_PARAMETER",
        {
          url: "area/village",
          params: {
            districtId: this.area.districtId,
            villageName: "",
            page: 0,
          },
        }
      );
      if (resp) {
        this.options.kelurahan = [{ text: "-- Pilih --", value: "" }];
        resp.data.data.content.map((i, index) => {
          const text = `${i.villageName}`.toUpperCase();
          const value = i.villageId;
          const postalcode = i.postalcode;
          this.options.kelurahan.push({ text, value, postalcode });
          if (index + 1 === resp.data.data.content.length) {
            if (this.mixin_data_identifier_cif_data) {
              this.form.cifPostalCode = this.mixin_data_identifier_cif_data?.cifPostalCode;
            }
          }
        });
      }
    },
    async getReferenceProvince() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "area/province",
            params: {
              provinceName: "",
              page: 0,
            },
          }
        );
        if (resp) {
          this.options.provinsi = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.content.map((i, index) => {
            const text = `${i.provinceName}`.toUpperCase();
            const value = i.provinceId;
            this.options.provinsi.push({ text, value });
            if (index + 1 === resp.data.data.content.length) {
              if (this.mixin_data_identifier_cif_data) {
                this.area.provinceId = this.mixin_data_identifier_cif_data?.villageId?.mdistrict?.mcity?.mprovince?.provinceId;
                this.onChangeProvince();
              }
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceNationality() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "nationality",
          params: {
            cifCategoryId: this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_CIF_CATEGORY_COMPANY,
          },
        });
        if (resp) {
          this.options.kewarganegaraan = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((index) => {
            const value = index.nationalityCode;
            const text = `${index.nationalityName}`.toUpperCase();
            this.options.kewarganegaraan.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceUserAccountOfficer() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_USER_MANAGEMENT",
          {
            params: {
              page: 0,
            },
            url: "v2/user/active-user",
          }
        );
        if (resp) {
          resp.data.data.content.map((index) => {
            const text = `${index.profileSurename} - ${index.userNik}`;
            const value = index.userId;
            this.options.aoPromosi.push({ text, value });
            this.options.aoHandling.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceGroupOwner() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-group-owner",
        });
        if (resp) {
          this.options.golonganPemilik = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((index) => {
            const value = index.cifGroupOwnerCode;
            const text = `${index.cifGroupOwnerName}`.toUpperCase();
            this.options.golonganPemilik.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceBusinessSegment() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              name: "",
              page: 0,
            },
            url: "business-segment",
          }
        );
        if (resp) {
          this.options.segmenBisnis = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.content.map((index) => {
            const value = index.businessSegmentCode;
            const text = `${index.businessSegmentName}`.toUpperCase();
            this.options.segmenBisnis.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceCompanyRelated() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-company-related",
        });
        if (resp) {
          this.options.hubunganDenganBank = [
            { text: "-- Pilih --", value: "" },
          ];
          resp.data.data.map((index) => {
            const text = `${index.cifCompanyRelatedName}`.toUpperCase();
            const value = index.cifCompanyRelatedCode;
            this.options.hubunganDenganBank.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceCifClassification() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-classification",
        });
        if (resp) {
          this.options.klasifikasiNasabah = [
            { text: "-- Pilih --", value: "" },
          ];
          resp.data.data.map((index) => {
            const value = index.cifClassificationCode;
            const text = `${index.cifClassificationName}`.toUpperCase();
            this.options.klasifikasiNasabah.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getReferenceSandiLocation() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "cif-sandi-location",
        });
        if (resp) {
          this.options.sandiLokasi = [{ text: "-- Pilih --", value: "" }];
          resp.data.data.map((index) => {
            const text = `${index.cifSandiLocationName}`.toUpperCase();
            const value = index.cifSandiLocationCode;
            this.options.sandiLokasi.push({ text, value });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    downloadPdf() {
      this.property.animation.modal.showModalPrintIdentityCif = true;
    },
    closePreview() {
      this.property.animation.modal.showModalPrintIdentityCif = false;
    },
    async onPressPrint() {
      this.isLoading = true;
      var element = document.getElementById("output-canvas");
      var opt = {
        marginLeft: 0.5,
        marginRight: 0.5,
        marginBottom: 0,
        marginTop: 0,
        filename: `CIF_${this.form.cifIdName}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      await this.simpleWait(1000);
      this.isLoading = false;

      html2pdf()
        .set(opt)
        .from(element)
        .save();

      // New Promise-based usage:
      // html2pdf()
      //   .from(element)
      //   .set(opt)
      //   .toPdf()
      //   .get("pdf")
      //   .then((pdf) => {
      //     pdf.autoPrint();
      //     const hiddFrame = document.createElement("iframe");
      //     const isSafari = /^((?!chrome|android).)*safari/i.test(
      //       window.navigator.userAgent
      //     );
      //     if (isSafari) {
      //       // fallback in safari
      //       hiddFrame.onload = () => {
      //         try {
      //           hiddFrame.contentWindow.document.execCommand(
      //             "print",
      //             false,
      //             null
      //           );
      //         } catch (e) {
      //           hiddFrame.contentWindow.print();
      //         }
      //       };
      //     }
      //     hiddFrame.src = pdf.output("bloburl");
      //     document.body.appendChild(hiddFrame);
      //   });
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    this.getDefaultFormValue();
    this.getReferenceIdCardType();
    this.getReferenceLegalEntity();
    this.getReferenceProvince();
    this.getReferenceNationality();
    this.getReferenceCifStatusDomicileLocation();
    this.getReferenceUserAccountOfficer();
    this.getReferenceGroupOwner();
    this.getReferenceBusinessSegment();
    this.getReferenceCompanyRelated();
    this.getReferenceCifClassification();
    this.getReferenceSandiLocation();
    this.getReferenceCifFamily();
  },
};
