export default {
  name: "UploadImage",
  props: {
    image: "",
    title: "",
    previewed: false,
  },
  data() {
    return {
      edit: {
        isEdit: false,
        image: "",
        imageData: "",
      },
      preview: {
        image: "",
        imageData: "",
      },
      loading: false,
    };
  },
  methods: {
    onFileChange(e) {
      this.preview.imageData = e;
      this.preview.image = URL.createObjectURL(e);
    },
    onCancel() {
      this.preview.imageData = "";
      this.preview.image = "";
    },
    onUpload() {
      this.$emit("onUpload", {
        imageData: this.preview.imageData,
      });
    },
    onDelete() {
      this.$emit("onDelete");
    },
    onFileChangeUpdate(e) {
      this.edit.imageData = e;
      this.edit.image = URL.createObjectURL(e);
    },
    onUploadUpdate() {
      this.$emit("onUpload", {
        imageData: this.edit.imageData,
      });
    },
    onCancelUpdate() {
      this.edit.isEdit = false;
      this.edit.imageData = "";
      this.edit.image = "";
    },
    onPrepareUpdate() {
      this.edit.isEdit = true;
    },
  },
};
